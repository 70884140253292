import {observer} from "mobx-react";
import {Outlet} from "react-router-dom";
import styles from "./styles.module.scss";
import {CssBaseline} from "@mui/material";

const LoggedOutLayout : React.FC = () => {
    return (
        <>
            <CssBaseline />
            <div className={styles.loggedOutContainer}>
                <Outlet />
            </div>
        </>
    )
}


export default observer(LoggedOutLayout);