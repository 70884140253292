import ApiUtil from "../utilities/api-util";
import {ApiResult, EditUserRequest, NewUserRequest, UserListItemResult} from "@mpsinvoices/modelsclient/src";

const ApiUrl = (path: string) => {
    return `Users/${path}`;
}

const UsersApi = {
    getUsers: () => ApiUtil.get<ApiResult<UserListItemResult[]>>(ApiUrl("GetUsers")),
    createUser: (model: NewUserRequest) => ApiUtil.post<ApiResult<UserListItemResult>>(ApiUrl("CreateUser"), model),
    editUser: (model: EditUserRequest) => ApiUtil.post<ApiResult<UserListItemResult>>(ApiUrl("EditUser"), model)    
}

export default UsersApi;