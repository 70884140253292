import {
    ApiResult,
    DocumentListItemResult,
    EditDocumentRequest,
    NewDocumentRequest
} from "@mpsinvoices/modelsclient/src";
import ApiUtil from "../utilities/api-util";

const ApiUrl = (path: string) => {
    return  `Documents/${path}`;
}

const DocumentApi = {
    getDocuments: () => ApiUtil.get<ApiResult<DocumentListItemResult>>(ApiUrl("GetDocuments")),
    searchDocuments: (model: {term: string}) => ApiUtil.get<ApiResult<DocumentListItemResult>>(ApiUrl("SearchDocuments"), model),
    createDocument: (model: NewDocumentRequest) => ApiUtil.post<ApiResult<DocumentListItemResult>>(ApiUrl("CreateDocument"), model),
    getForEdit:(model:{id: string}) => ApiUtil.get<ApiResult<EditDocumentRequest>>(ApiUrl("GetForEdit"), model),
    editDocument: (model: EditDocumentRequest) => ApiUtil.post<ApiResult<DocumentListItemResult>>(ApiUrl("EditDocument"), model),
    getDocumentPdf: (id: string) => ApiUtil.getDownloadFileUrl(ApiUrl(`GetDocumentPdf?id=${id}`)),
    getBlankDocumentPdf: (docType: number, fileName: string) => ApiUtil.getDownload(ApiUrl(`GetBlankDocumentPdf?docType=${docType}`), fileName)
}

export default DocumentApi;