export const AppPaths: any = {
    Home: {
        Path: "/",
        Url: "/"
    },
    Login:{
        Path: "/login",
        Url: '/login',
    },
    Documents: {
        Path: "/documents",
        Url: "/documents",
        ViewDocuments: {
            Path: "view",
            Url: "/documents/view"
        },
        NewDocument: {
            Path: "new",
            Url: "/documents/new"
        },
        EditDocument: {
            Path: "edit",
            Url: "/documents/edit"
        }
    },
    Settings:{
        Path: "/settings",
        Url: '/settings',
    },
    UserAccounts:{
        Path: "/user-accounts",
        Url: '/user-accounts',
    },
    AccessDenied: {
        Path: "/access-denied",
        Url: "/access-denied"
    },
    DisabledFeature: {
        Path: "/feature-unavailable",
        Url: "/feature-unavailable"
    },
    ServerError: {
        Path: "/server-error",
        Url: "/server-error"
    },
    NotFound: {
        Path: "/not-found",
        Url: "/not-found"
    }
}