import axios, {AxiosResponse} from "axios";
import {history} from "../../index";
import configData from "../../config/settings.json";
import {rootStore} from "../stores/root-store";
import {AppPaths} from "../../AppPaths";
import fileDownload from "js-file-download";
import {toast} from "react-toastify";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));

axios.defaults.baseURL = `${configData.API_URL}/api/`;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 60000;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(async (config) => {
    const token = rootStore.authenticationStore.token;
    const refreshToken = rootStore.authenticationStore.refreshToken;
    if (token) config.headers!.Authorization = `Bearer ${token}`;
    if (refreshToken) config.headers!["X-Refresh-Token"] = refreshToken;
    return config;
});

axios.interceptors.response.use(async response => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        await sleep();
    }
    return response;
}, async function (error: any){
    console.log(error);
    const {data, status} = error.response!;
    switch(status) {
        case 400:
            throw data;
        case 401:
            const originalRequest = error.config;
            const refreshToken = rootStore.authenticationStore.refreshToken;
            if (!originalRequest._retry && refreshToken) {
                originalRequest._retry = true;
                await rootStore.authenticationStore.refreshLogin();
                const token = rootStore.authenticationStore.token;
                const newRefreshToken2 = rootStore.authenticationStore.refreshToken;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                axios.defaults.headers["X-Refresh-Token"] = newRefreshToken2;
                return axios(originalRequest);
            } else {
                history.push(AppPaths.AccessDenied.Url);
            }
            break;
        case 403:
            const originalRequest2 = error.config;
            const refreshToken2 = rootStore.authenticationStore.refreshToken;
            if (!originalRequest2._retry && refreshToken2) {
                originalRequest2._retry = true;
                await rootStore.authenticationStore.refreshLogin();
                const token2 = rootStore.authenticationStore.token;
                const newRefreshToken2 = rootStore.authenticationStore.refreshToken;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token2;
                axios.defaults.headers["X-Refresh-Token"] = newRefreshToken2;
                return axios(originalRequest2);
            }
            break
        case 404:
            history.push(AppPaths.NotFound.Url)
            break;
        case 406:
            history.push(AppPaths.DisabledFeature.Url, data)
            break;
        case 500:
        case 504:
            toast.error("Error processing the request.")
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
});

const ApiUtil = {
    get: <T>(url: string, params?: any) => {
    const apiParams = new URLSearchParams();
    if (params) {
        Object.keys(params).forEach((key: any) => {
            if (params[key] !== null && params[key] !== undefined) {
                apiParams.append(key, params[key]);
            }
        });
    }
    return axios.get<T>(url, {params: apiParams}).then(responseBody);
},
post: <T>(url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
    put: (url: string, body?: {}) => axios.put(url, body).then(responseBody),
    patch: <T>(url: string, body?: {}) => axios.patch<T>(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    multipleGet: async (endpoints: { url: string, params?: any }[]) => {
    let responses = [];
    for (const endpoint of endpoints) {
        const apiParams = new URLSearchParams();
        if (endpoint.params) {
            Object.keys(endpoint.params).forEach((key: any) => {
                if (endpoint.params[key] !== null && endpoint.params[key] !== undefined) {
                    apiParams.append(key, endpoint.params[key]);
                }
            });
        }
        const result = await axios.get(endpoint.url, {params: apiParams});
        responses.push(responseBody(result));
    }
    return responses;
},
    postDownload: (url:string, downloadFileName: string, body?: {}) => axios.post(url, body, {
    responseType: 'blob'
}).then(response => fileDownload(response.data, downloadFileName)),
    getDownload: (url: string, downloadFileName: string) => axios.get(url, {
    responseType: 'blob',
}).then(response => fileDownload(response.data, downloadFileName)),
    getDownloadFileUrl: (url: string) => axios.get(url, {responseType: 'blob'})
        .then(response => {
            const file = new Blob([response.data], {type: "application/pdf"});
            return URL.createObjectURL(file);
        }),
    postStream: <T>(url: string, body: FormData) => axios.post(url, body, {
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
})
}

export default ApiUtil;



interface HandleApiBadRequestProps {
    error: any;
    redirectFunc?: () => void;
    toastMessage?: string;
    // TO-DO: Fix to use formik
    // @ts-ignore
    formSetErrorFunc?: (name: string, error: ErrorOption, options?: ({ shouldFocus: boolean } | undefined)) => void;
}

export function HandleApiBadRequest({error, redirectFunc, toastMessage, formSetErrorFunc}: HandleApiBadRequestProps) {
    console.log(error);
    let errorMessage = error.errorMessage;
    if (errorMessage) {
        errorMessage.forEach((error: any) => {
            if (error.member && formSetErrorFunc !== undefined) {
                formSetErrorFunc(error.member, {message: error.message});
            } else {
                // TO-DO: fix toast notifications
                // toast.error(error.message);
            }
        });
    } else if (toastMessage) {
        // TO-DO fix toast notifications
        // toast.error(toastMessage);
    }
    if (redirectFunc !== undefined) {
        redirectFunc();
    }
}