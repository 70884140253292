import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {EditDocumentRequest, NewDocumentRequest} from "@mpsinvoices/modelsclient/src";
import {CurrentDate} from "../../../core/utilities/date-helpers";
import {BusyStates} from "../../../core/utilities/busy-states";
import {useRootStore} from "../../../core/stores/root-store";
import DocumentApi from "../../../core/api/document-api";
import {toast} from "react-toastify";
import {AppPaths} from "../../../AppPaths";
import {useNavigate} from "react-router-dom";
import useQuery from "../../../core/utilities/use-query";
import DocumentEditor from "./components/document-editor";
import App from "../../../App";
import MpsSpinner from "../../shared/mps-spinner";


const NewDocument: React.FC = () => {
    const {busyStore} = useRootStore();
    const query = useQuery();
    const navigate = useNavigate();
    const [initialLoading, setInitialLoading] = useState(false);
    const [documentData, setDocumentData] = useState<NewDocumentRequest>({
        documentReference: "",
        documentDate: CurrentDate(),
        documentType: query.get("docType") ? Number(query.get("docType")) : 1,
        recipientId: "",
        documentItems: [],
        overrideTotal: false,
        linkedDocument: "",
        depositAmount: 0,
    });
    
    useEffect(() => {
        const duplicateFrom = query.get("duplicateFrom");
        if (duplicateFrom) {
            setInitialLoading(true);
            DocumentApi.getForEdit({id: duplicateFrom})
                .then(result => {
                    const model = result.model as EditDocumentRequest;
                    setDocumentData({
                        documentReference: model.documentReference,
                        documentDate: CurrentDate(),
                        documentType: query.get("docType") ? Number(query.get("docType")) : 1,
                        depositDueDate: model.depositDueDate,
                        depositAmount: model.depositAmount,
                        recipientId: model.recipientId,
                        overrideTotal: model.overrideTotal,
                        documentTotal: model.documentTotal,
                        documentItems: model.documentItems,
                        linkedDocument: model.documentId
                    })
                })
                .catch(error => {
                    navigate(AppPaths.Home);
                    console.log(error);
                    toast.error("Failed to duplicate document");
                })
                .finally(() => {
                    setInitialLoading(false);
                });
        }
    }, [query.get("duplicateFrom")])

    const handleGenerate = () => {
        busyStore.setBusyState(BusyStates.Document);
        DocumentApi.createDocument(documentData)
            .then(result => {
                let docType = "Quote";
                switch(documentData.documentType){
                    case 0: docType = "Invoice"; break;
                    case 1: docType = "Quote"; break;
                    case 2: docType = "Deposit Receipt"; break;
                }
                toast.success(`${docType} saved successfully!`);
                navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=${documentData.documentType}&id=${result.model.documentId}`);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Document);
            })
    }
    
    if(initialLoading) return <MpsSpinner noMessage />;
    
    return (
        <DocumentEditor
            documentData={documentData}
            initialStep={query.get("docType") ? 1 : 0}
            setDocumentData={setDocumentData}
            handleGenerate={handleGenerate}
        />
    )
}

export default observer(NewDocument);