import {observer} from "mobx-react";
import useQuery from "../../../core/utilities/use-query";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Card, CardActions, CardContent,
    Dialog,
    DialogActions, DialogContent, DialogTitle, Grid, Paper,
    styled, Table, TableBody,
    TableCell,
    tableCellClasses, TableContainer, TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {AppPaths} from "../../../AppPaths";
import {useNavigate} from "react-router-dom";
import configData from "../../../config/settings.json";
import DocumentApi from "../../../core/api/document-api";
import {useRootStore} from "../../../core/stores/root-store";
import {BusyStates} from "../../../core/utilities/busy-states";
import BusyIndicator from "../../shared/busy-indicator";
import {DocumentListItemResult} from "@mpsinvoices/modelsclient/src";
import {CurrencyFormatter, SumOfAmounts} from "../../../core/utilities/number-helpers";
import {FormatDate} from "../../../core/utilities/date-helpers";
import If from "../../shared/if";
import SendEmailDialogForm from "./components/send-email-dialog-form";
import MpsPdfViewer from "../../shared/mps-pdf-viewer";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    paddingLeft: 5,
    paddingRight: 5,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ViewDocuments: React.FC = () => {
    const {busyStore} = useRootStore();
    const query = useQuery();
    const navigate = useNavigate();

    const [docType, setDocType] = useState<number>(1);
    const [id, setId] = useState<string | null>(null);
    const [viewFile, setViewFile] = useState<string | null>(null);
    const [data, setData] = useState<DocumentListItemResult[]>([]);
    const [showEmailDialog, setShowEmailDialog] = useState(false);


    useEffect(() => {
        if (id != null) {
            busyStore.setBusyState(BusyStates.Document);
            DocumentApi.getDocumentPdf(id)
                .then((result: string) => {
                    setViewFile(result);
                })
                .catch(() => {
                    setViewFile(null);
                })
                .finally(() => {
                    busyStore.removeBusyState(BusyStates.Document);
                })
        } else {
            setViewFile(null);
            setId(null);
        }
    }, [id]);

    useEffect(() => {
        busyStore.setBusyState(BusyStates.Document);
        DocumentApi.getDocuments()
            .then(results => {
                setData(results.model);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Document);
            })
    }, [docType]);
    useEffect(() => {
        setId(query.get("id"));
    }, [query.get("id")])

    useEffect(() => {
        setDocType(query.get("docType") ? Number(query.get("docType")) : 1)
    }, [query.get("docType")])

    return (
        <>
            <BusyIndicator keyName={BusyStates.Document}/>
            <BusyIndicator keyName={BusyStates.Email}/>

            <If condition={docType === 0}>
                <Typography variant={"h3"} sx={{mb: 3}}>Invoices</Typography>
            </If>
            <If condition={docType === 1}>
                <Typography variant={"h3"} sx={{mb: 3}}>Quotes</Typography>
            </If>
            <If condition={docType === 2}>
                <Typography variant={"h3"} sx={{mb: 3}}>Deposit Receipt</Typography>
            </If>


            <Grid container spacing={4}>
                {data.filter(x => x.documentType === docType).map(item => (
                    <Grid
                        item
                        key={item.documentId}
                        xs={12}
                        md={6}
                    >
                        <Card
                            sx={{height: "100%", display: "flex", flexDirection: "column", p: 1}}
                            raised
                        >
                            <CardContent>
                                <Typography sx={{fontSize: 14}} color="primary" gutterBottom>
                                    {FormatDate(item.documentDate)}
                                </Typography>
                                <Typography variant={"h5"} component={"div"}>
                                    {item.documentReference}
                                </Typography>
                                <Typography sx={{mb: 1.5}} color="primary">
                                    {item.recipient}
                                </Typography>
                                <Typography variant={"h6"} component={"div"} color={"secondary"}>
                                    {CurrencyFormatter(item.totalAmount)}
                                </Typography>
                            </CardContent>

                            <CardActions sx={{mt: "auto", flexDirection: "row", justifyContent: "space-between"}}>
                                <Button className={"help-document-invoice-quote-view"} size="small" color={"info"}
                                        variant={"contained"} onClick={() => {
                                    setId(item.documentId);
                                }}>View</Button>
                                <If condition={item.linkedDocumentId !== undefined && item.linkedDocumentId !== null && item.linkedDocumentId !== ""}>
                                    <If condition={item.documentType === 1}>
                                        <Button size="small" color={"deposit"} variant={"contained"} onClick={() => {
                                            setId(item.linkedDocumentId ?? null);
                                        }}>View Deposit Receipt</Button>
                                    </If>
                                    <If condition={item.documentType === 2}>
                                        <Button size="small" color={"invoice"} variant={"contained"} onClick={() => {
                                            setId(item.linkedDocumentId ?? null);
                                        }}>View Invoice</Button>
                                    </If>
                                </If>
                                <If condition={item.linkedDocumentId === undefined || item.linkedDocumentId === null || item.linkedDocumentId === ""}>
                                    <If condition={item.documentType === 1}>
                                        <Button size="small" color={"deposit"} variant={"outlined"} onClick={() => {
                                            navigate(`${AppPaths.Documents.NewDocument.Url}?docType=2&duplicateFrom=${item.documentId}`);
                                        }}>Generate Deposit Receipt</Button>
                                    </If>
                                    <If condition={item.documentType === 2}>
                                        <Button size="small" color={"invoice"} variant={"outlined"} onClick={() => {
                                            navigate(`${AppPaths.Documents.NewDocument.Url}?docType=0&duplicateFrom=${item.documentId}`);
                                        }}>Generate Invoice</Button>
                                    </If>
                                </If>
                                <Button size="small" color={"secondary"} variant={"contained"} sx={{alignSelf: "end"}}
                                        onClick={() => {
                                            navigate(`${AppPaths.Documents.EditDocument.Url}?id=${item.documentId}`)
                                        }}>Edit</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Dialog
                className={"help-document-view-invoice-quote"}
                open={id !== null}
                fullWidth
                maxWidth={"xl"}
            >
                <Box sx={{
                    position: "relative",
                    lineHeight: 0
                }}>
                    {viewFile && (
                        <div style={{height: "calc(100vh - 200px)"}}>
                            <MpsPdfViewer
                                file={viewFile}
                                downloadName={id ?? "file-download"}
                            />
                        </div>
                    )}
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 20,
                            right: 20,
                            maxWidth: 140
                        }}
                    >
                        <Button
                            variant={"contained"}
                            fullWidth
                            sx={{mb: 2}}
                            onClick={() => {
                                setShowEmailDialog(true);
                            }}
                        >
                            Send Email
                        </Button>
                        <Button
                            variant={"contained"}
                            fullWidth
                            onClick={() => {
                                setId(null);
                                navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=${docType}`);
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={showEmailDialog}
                fullWidth
                maxWidth={"lg"}
            >
                <SendEmailDialogForm
                    onCancel={() => setShowEmailDialog(false)}
                    documentId={id}
                    onClose={() => setShowEmailDialog(false)}
                />
            </Dialog>
        </>
    )
}

export default observer(ViewDocuments);