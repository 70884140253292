import {makeAutoObservable} from "mobx";

interface BusyValues {
    [key: string]: any[];
}

export class BusyStore {
    busyStates: BusyValues = {};

    constructor() {
        makeAutoObservable(this);
    }

    isBusy = (key:string) => {
        return this.busyStates[key] && this.busyStates[key].length > 0;
    }

    setBusyState = (key: string) => {
        if(!this.busyStates[key]){
            this.busyStates[key] = [];
        }
        this.busyStates[key].push(true);
    }

    removeBusyState = (key: string) => {
        if(this.busyStates[key] && this.busyStates[key].length > 0){
            this.busyStates[key].splice(0,1);
        }
    }
}