import {observer} from "mobx-react";
import {Box, styled, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import {blue} from "@mui/material/colors";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import If from "../../../shared/if";

interface DepositPaidEditorProps {
    value?: number,
    onChange: (value: number | undefined) => void;
}

const CustomToggleButton = styled(ToggleButton)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    "&.Mui-selected" : {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        backgroundColor: theme.palette.secondary.main,
        "&:hover" : {
            backgroundColor: theme.palette.secondary.main
        }
    }
}))

interface NumericFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, NumericFormatCustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);

const DepositPaidEditor : React.FC<DepositPaidEditorProps> = props => {
    const [havePaid, setHavePaid] = useState<boolean>(props.value !== undefined || props.value === 0);

    const handleHavePaidChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean) => {
        setHavePaid(newValue);
        if(newValue){
            props.onChange(0);
        }
    }

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(Number(event.target.value));

    }
    
    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant={"h4"} sx={{mb: 3}}>Has a deposit been paid?</Typography>
            <Box sx={{display: "flex", justifyContent: "center", mb: 3}}>
                <ToggleButtonGroup
                    className={"help-document-type-selector"}
                    value={havePaid}
                    size={"large"}
                    exclusive
                    onChange={handleHavePaidChange}
                >
                    <CustomToggleButton value={false}>No</CustomToggleButton>
                    <CustomToggleButton value={true}>Yes</CustomToggleButton>
                </ToggleButtonGroup>
            </Box>
            <If condition={havePaid}>
                <Typography variant={"h4"} sx={{mb: 3}}>How much has been paid?</Typography>
                <TextField
                    value={props.value}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{width: 200, mb:3}}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAmountChange(event)}
                    fullWidth
                    required
                    InputProps={{
                        inputComponent: NumericFormatCustom as any
                    }}
                />
            </If>
            
        </Box>
    )
}

export default observer(DepositPaidEditor);