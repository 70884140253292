import {observer} from "mobx-react";
import React, {Component, useEffect, useRef, useState} from "react";
import autosize from "autosize";
import {TextField} from "@mui/material";

interface MpsTextareaProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MpsTextarea : React.FC<MpsTextareaProps> = props => {
    const textareaRef = useRef<Element>(null);
    const [value, setValue] = useState<string>("");
    
    useEffect(() => {
        setValue(props.value);
    }, []);
    
    useEffect(() => {
        if(textareaRef.current){
            autosize(textareaRef.current);
        }
    },[textareaRef]);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        props.onChange(event);
    }
    
    return(
            <TextField
                inputRef={textareaRef}
                value={value}
                variant={"outlined"}
                size={"small"}
                fullWidth
                required
                multiline
                rows={2}
                onChange={handleChange}
            />
        )
}

export default observer(MpsTextarea);