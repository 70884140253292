import {observer} from "mobx-react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as Yup from "yup";
import {ChangePasswordRequest} from "@mpsinvoices/modelsclient/src";
import MpsForm from "../../../shared/mps-form";
import {FormikHelpers, FormikValues} from "formik";
import {useRootStore} from "../../../../core/stores/root-store";
import {BusyStates} from "../../../../core/utilities/busy-states";
import MpsInput from "../../../shared/mps-form/components/mps-input";
import MpsFormActions from "../../../shared/mps-form/components/mps-form-actions";
import ProfileApi from "../../../../core/api/profile-api";
import {toast} from "react-toastify";
import {MpsFormErrorHandler} from "../../../shared/mps-form/handlers/mps-form-error-handler";
import BusyIndicator from "../../../shared/busy-indicator";

interface ChangePasswordDialogProps {
    handleClose: () => void;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = props => {
    const {busyStore} = useRootStore();

    const changePasswordModel: ChangePasswordRequest = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    }

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        newPassword: Yup.string().required("New password is required"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], "Password must match")
    });

    const handleSubmit = (values: (FormikValues & ChangePasswordRequest), actions: FormikHelpers<FormikValues & ChangePasswordRequest>) => {
        
        busyStore.setBusyState(BusyStates.ChangePasswordForm);
        ProfileApi.changePassword(values)
            .then(() => {
                props.handleClose();
                toast.success("Password changed successfully!");
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.ChangePasswordForm);
            });
    }

    return (
        <>
            <BusyIndicator keyName={BusyStates.ChangePasswordForm}/>
            <Dialog open={true}>
                <DialogTitle>Change Password</DialogTitle>
                <MpsForm<ChangePasswordRequest>
                    initialValues={changePasswordModel}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formProps => (
                        <>
                            <DialogContent>
                                <Box sx={{pt: 2}}>
                                    <MpsInput
                                        label={"Old Password"}
                                        required={true}
                                        formikName={"oldPassword"}
                                        isPassword={true}
                                    />
                                    <MpsInput
                                        label={"New Password"}
                                        required={true}
                                        formikName={"newPassword"}
                                        isPassword={true}
                                    />
                                    <MpsInput
                                        label={"Confirm New Password"}
                                        required={true}
                                        formikName={"confirmNewPassword"}
                                        isPassword={true}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <MpsFormActions
                                    formProps={formProps}
                                    onCancelAction={props.handleClose}
                                    saveText={"Change Password"}
                                />
                            </DialogActions>
                        </>
                    )}
                </MpsForm>
            </Dialog>
        </>
    )
}

export default observer(ChangePasswordDialog);