import ApiUtil from "../utilities/api-util";
import {AddEmailAddressRequest, ApiResult, EmailAddressItemResult, SendEmailRequest} from "@mpsinvoices/modelsclient/src";

const ApiUrl = (path: string) => {
    return `Email/${path}`;
}

const EmailApi = {
    searchEmailAddress: (model: {term: string}) => ApiUtil.get<ApiResult<EmailAddressItemResult[]>>(ApiUrl("SearchEmailAddress"), model),
    addEmailAddress: (model: AddEmailAddressRequest) => ApiUtil.post<ApiResult<EmailAddressItemResult>>(ApiUrl("AddEmailAddress"), model),
    sendEmail: (model: SendEmailRequest) => ApiUtil.post<ApiResult<Boolean>>(ApiUrl("SendEmail"), model),
    getEmailAddressFromDocument: (model: {documentId: string}) => ApiUtil.get<ApiResult<EmailAddressItemResult>>(ApiUrl("GetEmailAddressFromDocument"), model),
};

export default EmailApi;