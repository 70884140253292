import {Box, styled, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {DocumentTypes} from "@mpsinvoices/modelsclient/src";
import {observer} from "mobx-react";
import {blue, orange, teal} from "@mui/material/colors";

interface DocumentTypeSelector {
    value: DocumentTypes;
    onChange: (newDocumentType: DocumentTypes) => void;
}

const QuoteToggleButton = styled(ToggleButton)(({theme}) => ({
    color: blue[600],
    fontWeight: "bold",
    "&.Mui-selected" : {
        color: theme.palette.getContrastText(blue[600]),
        backgroundColor: blue[600],
        "&:hover" : {
            backgroundColor: blue[600]
        }
    }
}))

const DepositToggleButton = styled(ToggleButton)(({theme}) => ({
    color: teal[600],
    fontWeight: "bold",
    "&.Mui-selected" : {
        color: theme.palette.getContrastText(teal[600]),
        backgroundColor: teal[600],
        "&:hover" : {
            backgroundColor: teal[600]
        }
    }
}))

const InvoiceToggleButton = styled(ToggleButton)(({theme}) => ({
    color: orange[600],
    fontWeight: "bold",
    "&.Mui-selected" : {
        color: theme.palette.getContrastText(orange[600]),
        backgroundColor: orange[600],
        "&:hover" : {
            backgroundColor: orange[600]
        }
    }
}))

const DocumentTypeSelector: React.FC<DocumentTypeSelector> = props => {
    const handleDocumentTypeChange = (event: React.MouseEvent<HTMLElement>, newDocumentType: number) => {
        props.onChange(newDocumentType);
    }
    return (
        <Box sx={{mb: 3}}>
            <Typography variant={"h4"} textAlign={"center"} sx={{mb: 3}}>Do you want to create?</Typography>
            <Box sx={{display: "flex", justifyContent: "center"}}>
                <ToggleButtonGroup
                    className={"help-document-type-selector"}
                    value={props.value}
                    size={"large"}
                    exclusive
                    onChange={handleDocumentTypeChange}
                >
                    <QuoteToggleButton value={1}>Quote</QuoteToggleButton>
                    <DepositToggleButton value={2}>Deposit</DepositToggleButton>
                    <InvoiceToggleButton value={0}>Invoice</InvoiceToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    )
}

export default observer(DocumentTypeSelector);