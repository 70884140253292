import {EditDocumentRequest, NewDocumentRequest, RecipientResult} from "@mpsinvoices/modelsclient/src";
import {observer} from "mobx-react";
import {
    Box,
    Button, IconButton,
    Paper,
    styled,
    Table, TableBody,
    TableCell,
    tableCellClasses, TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import If from "../../../shared/if";
import {FormatDate} from "../../../../core/utilities/date-helpers";
import {ArrowDownward, ArrowUpward, Delete} from "@mui/icons-material";
import React from "react";
import {CurrencyFormatter, SumOfAmounts} from "../../../../core/utilities/number-helpers";

interface SummaryOverviewProps {
    data: NewDocumentRequest | EditDocumentRequest;
    recipient: RecipientResult | null;
    docTypeCallback?: () => void;
    docDateCallback: () => void;
    docRecipientCallback: () => void;
    docLineItemCallback: () => void;
    docDepositCallback: () => void;
    skipDocumentType?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: 5,
    paddingRight: 5,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SummaryOverview: React.FC<SummaryOverviewProps> = props => {
    const getDocTypeName = () => {
        switch (props.data.documentType) {
            case 0:
                return "Invoice";
            case 1:
                return "Quote";
            default:
                return "Quote";
        }
    }

    return (
        <Box sx={{mb: 3, width: 800, maxWidth: "100%", mx: "auto", p: 3}} component={Paper} className={"help-document-summary"}>
            <Box sx={{mb: 5}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "end", mb: 1}}>
                    <Typography variant={"h6"} sx={{flexGrow: 1}}>Document Type</Typography>
                    <If condition={props.docTypeCallback != undefined && props.skipDocumentType !== true}>
                        <Button
                            className={"help-document-summary-change-buttons"}
                            size={"small"}
                            color={"secondary"}
                            onClick={() => {
                                if (props.docTypeCallback) {
                                    props.docTypeCallback()
                                }
                            }}
                        >
                            Change
                        </Button>
                    </If>
                </Box>
                <Typography sx={{fontWeight: "bold"}}>Document Type</Typography>
                <Typography>{getDocTypeName()}</Typography>
            </Box>

            <Box sx={{mb: 5}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "end", mb: 1}}>
                    <Typography variant={"h6"} sx={{flexGrow: 1}}>Reference & Data</Typography>
                    <Button
                        className={"help-document-summary-change-buttons"}
                        size={"small"}
                        color={"secondary"}
                        onClick={() => {
                            props.docDateCallback()
                        }}
                    >
                        Change
                    </Button>
                </Box>
                <Typography sx={{fontWeight: "bold"}}>Date</Typography>
                <Typography sx={{mb: 3}}>{FormatDate(props.data.documentDate)}</Typography>


                <Typography sx={{fontWeight: "bold"}}>Your Reference</Typography>
                <Typography>{props.data.documentReference}</Typography>
            </Box>

            <Box sx={{mb: 5}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "end", mb: 1}}>
                    <Typography variant={"h6"} sx={{flexGrow: 1}}>Recipient</Typography>
                    <Button
                        className={"help-document-summary-change-buttons"}
                        size={"small"}
                        color={"secondary"}
                        onClick={() => {
                            props.docRecipientCallback()
                        }}
                    >
                        Change
                    </Button>
                </Box>
                <Typography sx={{fontWeight: "bold"}}>{props.recipient?.name}</Typography>
                {props.recipient?.address.split(",").map((value) => (
                    <Typography key={value.trim()}>
                        {value.trim()}
                    </Typography>
                ))}

                <If condition={props.recipient?.phoneNumber !== undefined && props.recipient?.phoneNumber !== "" && props.recipient?.phoneNumber !== null}>
                    <Typography>
                        {props.recipient?.phoneNumber}
                    </Typography>
                </If>
            </Box>

            <Box sx={{mb: props.data.documentType == 2 ? 5 : 0}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "end", mb: 1}}>
                    <Typography variant={"h6"} sx={{flexGrow: 1}}>Line Items</Typography>
                    <Button
                        className={"help-document-summary-change-buttons"}
                        size={"small"}
                        color={"secondary"}
                        onClick={() => {
                            props.docLineItemCallback()
                        }}
                    >
                        Change
                    </Button>
                </Box>
                <TableContainer sx={{width: "100%"}} component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{fontWeight: "bold"}}>Description</StyledTableCell>
                                <StyledTableCell sx={{fontWeight: "bold", textAlign: "right", whiteSpace: "nowrap"}} width={10}>Amount</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.documentItems.map((item, index) => (
                                <StyledTableRow key={`lineItemRow_${index}`}>
                                    <StyledTableCell>
                                        <Typography style={{whiteSpace: "pre-line"}}>{item.description}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={10} sx={{textAlign: "right", whiteSpace: "nowrap"}}>
                                        <If condition={item.includeInTotal}>
                                            <Typography>{CurrencyFormatter(item.amount ?? 0)}</Typography>
                                        </If>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <If condition={props.data.documentType === 0 && props.data.depositAmount !== undefined && props.data.depositAmount > 0}>
                                <StyledTableRow>
                                    <StyledTableCell sx={{textAlign: "right"}}>
                                        <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>TOTAL:</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={10} sx={{textAlign: "right", whiteSpace: "nowrap"}}>
                                        <If condition={props.data.depositAmount !== undefined && props.data.depositAmount > 0}>
                                            <If condition={props.data.overrideTotal}>

                                                <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                                    {CurrencyFormatter(props.data.documentTotal ?? 0)}
                                                </Typography>
                                            </If>
                                            <If condition={!props.data.overrideTotal}>
                                                <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                                    {SumOfAmounts(props.data.documentItems.map(x => x.amount ?? 0))}
                                                </Typography>
                                            </If>
                                        </If>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={{textAlign: "right"}}>
                                        <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>DEPOSIT PAID:</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={10} sx={{textAlign: "right", whiteSpace: "nowrap"}}>
                                        <If condition={props.data.depositAmount !== undefined && props.data.depositAmount > 0}>
                                            <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                                {CurrencyFormatter(0 - (props.data.depositAmount ?? 0))}
                                            </Typography>
                                        </If>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={{textAlign: "right"}}>
                                        <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>REMAINING TO PAY:</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={10} sx={{textAlign: "right", whiteSpace: "nowrap"}}>
                                        <If condition={props.data.overrideTotal}>
                                            <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{CurrencyFormatter((props.data.documentTotal ?? 0) - (props.data.depositAmount ?? 0))}</Typography>
                                        </If>
                                        <If condition={!props.data.overrideTotal}>
                                            <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{SumOfAmounts(props.data.documentItems.map(x => x.amount ?? 0), (props.data.depositAmount ?? 0))}</Typography>
                                        </If>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </If>
                            <If condition={!(props.data.documentType === 0 && props.data.depositAmount !== undefined && props.data.depositAmount > 0)}>
                                <StyledTableRow>
                                    <StyledTableCell sx={{textAlign: "right"}}>
                                        <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>TOTAL DUE:</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={10} sx={{textAlign: "right", whiteSpace: "nowrap"}}>
                                        <If condition={props.data.overrideTotal}>
                                            <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{CurrencyFormatter((props.data.documentTotal ?? 0) - (props.data.depositAmount ?? 0))}</Typography>
                                        </If>
                                        <If condition={!props.data.overrideTotal}>
                                            <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{SumOfAmounts(props.data.documentItems.map(x => x.amount ?? 0), (props.data.depositAmount ?? 0))}</Typography>
                                        </If>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </If>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            
            <If condition={props.data.documentType == 2}>
                <Box>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "end", mb: 1}}>
                        <Typography variant={"h6"} sx={{flexGrow: 1}}>Deposit Details</Typography>
                        <Button
                            className={"help-document-summary-change-buttons"}
                            size={"small"}
                            color={"secondary"}
                            onClick={() => {
                                props.docDepositCallback()
                            }}
                        >
                            Change
                        </Button>
                    </Box>
                    <Typography sx={{fontWeight: "bold"}}>Amount</Typography>
                    <Typography sx={{mb: 3}}>{props.data.depositAmount ? CurrencyFormatter(props.data.depositAmount) : "Not set"}</Typography>


                    <Typography sx={{fontWeight: "bold"}}>Date Due</Typography>
                    <Typography>{props.data.depositDueDate ? FormatDate(props.data.depositDueDate) : "Not set"}</Typography>
                </Box>
            </If>
            
        </Box>
    )
}

export default observer(SummaryOverview);