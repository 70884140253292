import {observer} from "mobx-react";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AppPaths} from "../../../AppPaths";
import {DocumentTypes} from "@mpsinvoices/modelsclient/src";
import {MpsTheme} from "../../../core/utilities/mps-theme";

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                mt: 5
            }}
        >
            <Box sx={{mb: 3}}>
                <Typography variant={"h3"} textAlign={"center"} component={"h3"}>What would you like to do?</Typography>
            </Box>
            <Box
                className={"help-target-dashboard-new-document"}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    mb: 3
                }}
            >
                <Button
                    size={"large"}
                    fullWidth
                    variant={"contained"}
                    color={"quote"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.NewDocument.Url}?docType=1`)
                    }}
                >
                    New Quote
                </Button>
                <Button
                    size={"large"}
                    fullWidth
                    variant={"contained"}
                    color={"deposit"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.NewDocument.Url}?docType=2`)
                    }}
                >
                    New Deposit Receipt
                </Button>
                <Button
                    size={"large"}
                    fullWidth
                    variant={"contained"}
                    color={"invoice"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.NewDocument.Url}?docType=0`)
                    }}
                >
                    New Invoice
                </Button>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    mb: 3
                }}
            >
                <Button
                    size={"large"}
                    fullWidth
                    variant={"outlined"}
                    color={"quote"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=1`)
                    }}
                >
                    View Quote
                </Button>
                <Button
                    size={"large"}
                    fullWidth
                    variant={"outlined"}
                    color={"deposit"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=1`)
                    }}
                >
                    View Deposit Receipt
                </Button>
                <Button
                    size={"large"}
                    fullWidth
                    variant={"outlined"}
                    color={"invoice"}
                    style={{fontSize: "1.5rem", fontWeight: 'bold'}}
                    onClick={() => {
                        navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=0`)
                    }}
                >
                    View Invoice
                </Button>
            </Box>            
        </Box>
    )
}

export default observer(Dashboard);