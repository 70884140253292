import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {ApiResult, EditSettingsRequest} from "@mpsinvoices/modelsclient/src";
import {useRootStore} from "../../../core/stores/root-store";
import {BusyStates} from "../../../core/utilities/busy-states";
import SettingsApi from "../../../core/api/settings-api";
import BusyIndicator from "../../shared/busy-indicator";
import MpsForm from "../../shared/mps-form";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import {Button, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import MpsInput from "../../shared/mps-form/components/mps-input";
import MpsHtmlInput from "../../shared/mps-form/components/mps-html-input";
import {toast} from "react-toastify";
import {MpsFormErrorHandler} from "../../shared/mps-form/handlers/mps-form-error-handler";

const Settings: React.FC = () => {
    const {busyStore} = useRootStore();
    const [data, setData] = useState<EditSettingsRequest | null>(null);

    const LoadSettings = () => {
        busyStore.setBusyState(BusyStates.Settings);
        SettingsApi.getSettings()
            .then((result : ApiResult<EditSettingsRequest>) => {
                setData({
                    addressLine1: result.model?.addressLine1 ?? "",
                    qouteTextAboveTable: result.model?.qouteTextAboveTable ?? "",
                    qouteDescriptionColumnHeader: result.model?.qouteDescriptionColumnHeader ?? "",
                    qouteSubtotalColumnHeader: result.model?.qouteSubtotalColumnHeader ?? "",
                    qouteTotalDueHeader: result.model?.qouteTotalDueHeader ?? "",
                    qouteFooter: result.model?.qouteFooter ?? "",
                    addressLine3: result.model?.addressLine3 ?? "",
                    town: result.model?.town ?? "",
                    county: result.model?.county ?? "",
                    email: result.model?.email ?? "",
                    postcode: result.model?.postcode ?? "",
                    invoiceTitle: result.model?.invoiceTitle ?? "",
                    phone: result.model?.phone ?? "",
                    invoiceTextAboveTitle: result.model?.invoiceTextAboveTitle ?? "",
                    addressLine2: result.model?.addressLine2 ?? "",
                    invoiceDescriptionColumnHeader: result.model?.invoiceDescriptionColumnHeader ?? "",
                    invoiceSubtotalColumnHeader: result.model?.invoiceSubtotalColumnHeader ?? "",
                    invoiceTotalDueHeader: result.model?.invoiceTotalDueHeader ?? "",
                    invoiceFooter: result.model?.invoiceFooter ?? "",
                    qouteTitle: result.model?.qouteTitle ?? "",
                    invoiceDepositPaid: result.model?.invoiceDepositPaid ?? "",
                    depositTitle: result.model?.depositTitle ?? "",
                    depositTextAboveTable: result.model?.depositTextAboveTable ?? "",
                    depositDescriptionColumnHeader: result.model?.depositDescriptionColumnHeader ?? "",
                    depositSubtotalColumnHeader: result.model?.depositSubtotalColumnHeader ?? "",
                    depositTotalDueHeader: result.model?.depositTotalDueHeader ?? "",
                    depositFooter: result.model?.depositFooter ?? "",
                    invoiceRemainingToPay: result.model?.invoiceRemainingToPay ?? ""
                });
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Settings);
            });
    }
    
    useEffect(() => {
        LoadSettings();
    },[]);
    
    const validationSchema = Yup.object().shape({
        addressLine1: Yup.string(),
        qouteTextAboveTable: Yup.string(),
        qouteDescriptionColumnHeader: Yup.string(),
        qouteSubtotalColumnHeader: Yup.string(),
        qouteTotalDueHeader: Yup.string(),
        qouteFooter: Yup.string(),
        addressLine3: Yup.string(),
        town: Yup.string(),
        county: Yup.string(),
        email: Yup.string(),
        postcode: Yup.string(),
        invoiceTitle: Yup.string(),
        phone: Yup.string(),
        invoiceTextAboveTitle: Yup.string(),
        addressLine2: Yup.string(),
        invoiceDescriptionColumnHeader: Yup.string(),
        invoiceSubtotalColumnHeader: Yup.string(),
        invoiceTotalDueHeader: Yup.string(),
        invoiceFooter: Yup.string(),
        qouteTitle: Yup.string(),
    });
    
    const handleSubmit = (values: (FormikValues & EditSettingsRequest), actions: FormikHelpers<FormikValues & EditSettingsRequest>) => {
        busyStore.setBusyState(BusyStates.Settings);
        SettingsApi.saveSettings(values)
            .then(() => {
                toast.success("Settings saved successfully!")
                LoadSettings();
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Settings);
            })
    }

    if (data == null) return <BusyIndicator keyName={BusyStates.Settings} />;

    return (
        <>
            <BusyIndicator keyName={BusyStates.Settings} />
            <MpsForm<EditSettingsRequest>
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formProps => (
                    <Container maxWidth={"md"}>
                        <Card
                            raised
                            sx={{mb: 3}}
                        >
                            <CardContent>
                                <Typography variant={"h5"} sx={{mb: 3}}>Document From Address</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"addressLine1"}
                                            label={"Address Line 1"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"addressLine2"}
                                            label={"Address Line 2"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"addressLine3"}
                                            label={"Address Line 3"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"town"}
                                            label={"Town"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"county"}
                                            label={"County"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"postcode"}
                                            label={"Postal Code"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"email"}
                                            label={"Email Address"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"phone"}
                                            label={"Phone Number"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign={"right"}>
                                        <Button
                                            type={"submit"}
                                            variant={"contained"}
                                            color={"primary"}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card
                            raised
                            sx={{mb: 3}}
                        >
                            <CardContent>
                                <Typography variant={"h5"} sx={{mb: 3}}>Quote Document Text</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"qouteTitle"}
                                            label={"Document Title"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"qouteDescriptionColumnHeader"}
                                            label={"Line Item Description Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"qouteSubtotalColumnHeader"}
                                            label={"Line Item Amount Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"qouteTotalDueHeader"}
                                            label={"Document Total Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"qouteTextAboveTable"}
                                            label={"Document Text Above Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"qouteFooter"}
                                            label={"Document Text Below Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign={"right"}>
                                        <Button
                                            type={"submit"}
                                            variant={"contained"}
                                            color={"primary"}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card
                            raised
                            sx={{mb: 3}}
                        >
                            <CardContent>
                                <Typography variant={"h5"} sx={{mb: 3}}>Deposit Document Text</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"depositTitle"}
                                            label={"Document Title"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"depositDescriptionColumnHeader"}
                                            label={"Line Item Description Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"depositSubtotalColumnHeader"}
                                            label={"Line Item Amount Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"depositTotalDueHeader"}
                                            label={"Document Total Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"depositTextAboveTable"}
                                            label={"Document Text Above Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"depositFooter"}
                                            label={"Document Text Below Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign={"right"}>
                                        <Button
                                            type={"submit"}
                                            variant={"contained"}
                                            color={"primary"}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card
                            raised
                            sx={{mb: 3}}
                        >
                            <CardContent>
                                <Typography variant={"h5"} sx={{mb: 3}}>Invoice Document Text</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"invoiceTitle"}
                                            label={"Document Title"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"invoiceDescriptionColumnHeader"}
                                            label={"Line Item Description Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"invoiceSubtotalColumnHeader"}
                                            label={"Line Item Amount Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"invoiceTotalDueHeader"}
                                            label={"Document Total Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MpsInput
                                            formikName={"invoiceDepositPaid"}
                                            label={"Document Deposit Paid Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"invoiceRemainingToPay"}
                                            label={"Document Remaining To Pay Header"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"invoiceTextAboveTitle"}
                                            label={"Document Text Above Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MpsHtmlInput
                                            formikName={"invoiceFooter"}
                                            label={"Document Text Below Line Items"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign={"right"}>
                                        <Button
                                            type={"submit"}
                                            variant={"contained"}
                                            color={"primary"}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                )}
            </MpsForm>
        </>
    )
}

export default observer(Settings);