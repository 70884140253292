import {observer} from "mobx-react";
import React, {useState} from "react";
import {Box, TextField, Typography} from "@mui/material";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import DateSelector from "./date-selector";

interface DepositToPayEditorProps {
    value: number | undefined;
    date: Date | undefined;
    onAmountChange: (amount: number) => void;
    onDateChange: (date: Date) => void;
}

interface NumericFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, NumericFormatCustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);

const DepositToPayEditor : React.FC<DepositToPayEditorProps> = props => {
    const [amount, setAmount] = useState<number>(props.value ?? 0);
    
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(event.target.value));
        props.onAmountChange(Number(event.target.value));
        
    }
    
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <Typography
                variant={"h4"}
                sx={{mb: 3}}
            >
                How much is the deposit for?
            </Typography>

            <TextField
                value={amount}
                variant={"outlined"}
                size={"medium"}
                sx={{width: 200, mb:3}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAmountChange(event)}
                fullWidth
                required
                InputProps={{
                    inputComponent: NumericFormatCustom as any
                }}
            />

            <Typography
                variant={"h4"}
                sx={{mb: 3}}
            >
                When is the deposit due?
            </Typography>
            
            <Box>
                <DateSelector
                    value={props.date ?? new Date()}
                    onChange={value => {
                        props.onDateChange(value);
                    }}
                />
            </Box>
        </Box>
    )
}

export default observer(DepositToPayEditor);