import {observer} from "mobx-react";
import BusyIndicator from "../../../shared/busy-indicator";
import {BusyStates} from "../../../../core/utilities/busy-states";
import {Box, Button, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import DocumentTypeSelector from "./document-type-selector";
import DateSelector from "./date-selector";
import RecipientSelector from "./recipient-selector";
import LineItemEditor from "./line-item-editor";
import {
    DocumentItemRequest, DocumentTypes,
    EditDocumentRequest,
    NewDocumentRequest,
    RecipientResult
} from "@mpsinvoices/modelsclient/src";
import SummaryOverview from "./summary-overview";
import {useEffect, useState} from "react";
import useQuery from "../../../../core/utilities/use-query";
import DepositToPayEditor from "./deposit-to-pay-editor";
import If from "../../../shared/if";
import DepositPaidEditor from "./deposit-paid-editor";

interface DocumentEditorProps<T = NewDocumentRequest | EditDocumentRequest> {
    documentData: T,
    setDocumentData: (data: T) => void;
    handleGenerate: () => void;
    skipDocumentTypeSelector?: boolean;
    initialStep?: number;
}

const stepsWithoutDeposit = ["Document Type", "Reference & Date", "Recipient", "Line Items", "Summary"];
const stepsWithDeposit = ["Document Type", "Reference & Date", "Recipient", "Line Items", "Deposit Details", "Summary"];

function DocumentEditor<T = NewDocumentRequest | EditDocumentRequest>(props: DocumentEditorProps<T>) {
    const {documentData, setDocumentData, handleGenerate} = props;
    const [steps, setSteps] = useState<string[]>([]);
    const [activeStep, setActiveStep] = useState<number>(props.initialStep ?? 0);

    useEffect(() => {
        let currentSteps = [...stepsWithoutDeposit];
        switch ((documentData as NewDocumentRequest | EditDocumentRequest).documentType) {
            case 0:
            case 2:
                currentSteps = [...stepsWithDeposit];
                break;
        }

        console.log(currentSteps);

        if (props.skipDocumentTypeSelector === true) {
            currentSteps.shift();
        }

        setSteps(currentSteps);
    }, [props.skipDocumentTypeSelector, (documentData as NewDocumentRequest | EditDocumentRequest).documentType]);

    const [recipientData, setRecipientData] = useState<RecipientResult | null>(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const FirstStepButtons = (props: { disabled?: boolean, onNext?: () => void }) => {
        return (
            <Box textAlign={"center"}>
                <Button
                    onClick={() => {
                        if (props.onNext) {
                            props.onNext();
                        }
                        handleNext();
                    }}
                    disabled={props.disabled === true}
                    variant={"contained"}
                    size={"large"}>
                    Next
                </Button>
            </Box>
        )
    }

    const StepButtons = (props: { disabled?: boolean, onNext?: () => void }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "1rem"
                }}
            >
                <Button
                    onClick={handleBack}
                    variant={"outlined"}
                    size={"large"}
                >
                    Previous
                </Button>
                <Button
                    onClick={() => {
                        if (props.onNext) {
                            props.onNext();
                        }
                        handleNext();
                    }}
                    variant={"contained"}
                    size={"large"}
                    disabled={props.disabled === true}
                >
                    Next
                </Button>
            </Box>
        )
    }

    const LastStepButtons = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "1rem"
                }}
            >
                <Button
                    onClick={handleBack}
                    variant={"outlined"}
                    size={"large"}
                >
                    Previous
                </Button>
                <Button
                    className={"help-document-summary-generate-button"}
                    onClick={() => {
                        handleGenerate();
                    }}
                    variant={"contained"}
                    size={"large"}
                >
                    Save & Generate
                </Button>
            </Box>
        )
    }

    const getStepFromName = (name: string) => {
        return steps.findIndex((x => x === name));
    }
    if (steps.length === 0) return null;

    return (
        <>
            <BusyIndicator keyName={BusyStates.Document}/>
            <Box>
                <Stepper activeStep={activeStep} sx={{mb: 5}}>
                    {steps.map((label, index) => (
                        <Step
                            key={label}
                            sx={{
                                cursor: index < activeStep ? "pointer" : "initial"
                            }}
                            onClick={() => {
                                if (index < activeStep) {
                                    setActiveStep(index);
                                }
                            }}
                        >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                    }
                </Stepper>
                <Box sx={{display: activeStep === getStepFromName("Document Type") ? "block" : "none"}}>
                    <DocumentTypeSelector
                        value={(documentData as NewDocumentRequest | EditDocumentRequest).documentType}
                        onChange={value => {
                            setDocumentData({
                                ...documentData,
                                documentType: value
                            })
                        }}
                    />
                    <FirstStepButtons/>
                </Box>
                <Box sx={{display: activeStep === getStepFromName("Reference & Date") ? "block" : "none"}}>
                    <Typography variant={"h4"} textAlign={"center"} sx={{mb: 3}}>Select the date and enter your
                        reference</Typography>
                    <DateSelector
                        value={(documentData as NewDocumentRequest | EditDocumentRequest).documentDate}
                        onChange={value => {
                            setDocumentData({
                                ...documentData,
                                documentDate: value
                            })
                        }}
                    />

                    <Box
                        sx={{
                            mb: 3,
                            width: "100%",
                            maxWidth: "600px",
                            display: "flex",
                            mx: "auto"
                        }}
                    >
                        <TextField
                            className={"help-document-reference"}
                            label={"Your Reference"}
                            value={(documentData as NewDocumentRequest | EditDocumentRequest).documentReference}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setDocumentData({
                                    ...documentData,
                                    documentReference: event.target.value
                                });
                            }}
                            required
                            fullWidth
                        />
                    </Box>
                    {getStepFromName("Reference & Date") === 0 ? (
                        <FirstStepButtons
                            disabled={!(documentData as NewDocumentRequest | EditDocumentRequest).documentReference}/>
                    ) : (
                        <StepButtons
                            disabled={!(documentData as NewDocumentRequest | EditDocumentRequest).documentReference}/>
                    )}

                </Box>
                <Box sx={{display: activeStep === getStepFromName("Recipient") ? "block" : "none"}}>
                    <RecipientSelector
                        value={(documentData as NewDocumentRequest | EditDocumentRequest).recipientId}
                        documentType={(documentData as NewDocumentRequest | EditDocumentRequest).documentType}
                        onChange={value => {
                            setDocumentData({
                                ...documentData,
                                recipientId: value
                            })
                        }}
                        callback={value => setRecipientData(value)}
                    />
                    <StepButtons disabled={!(documentData as NewDocumentRequest | EditDocumentRequest).recipientId}/>
                </Box>
                <Box sx={{display: activeStep === getStepFromName("Line Items") ? "block" : "none"}}>
                    <LineItemEditor
                        value={(documentData as NewDocumentRequest | EditDocumentRequest).documentItems}
                        totalValue={(documentData as NewDocumentRequest | EditDocumentRequest).documentTotal ?? 0}
                        totalOverrideValue={(documentData as NewDocumentRequest | EditDocumentRequest).overrideTotal}
                        documentType={(documentData as NewDocumentRequest | EditDocumentRequest).documentType}
                        onChange={value => {
                            setDocumentData({
                                ...documentData,
                                documentItems: value
                            })
                        }}
                        onTotalChange={value => {
                            setDocumentData({
                                ...documentData,
                                documentTotal: value
                            })
                        }}
                        onTotalOverrideChange={value => {
                            setDocumentData({
                                ...documentData,
                                overrideTotal: value
                            })
                        }}
                    />
                    <StepButtons
                        disabled={(documentData as NewDocumentRequest | EditDocumentRequest).documentItems.length === 0}
                        onNext={() => {
                            let documentItems: DocumentItemRequest[] = [];
                            for (let i = 0; i < (documentData as NewDocumentRequest | EditDocumentRequest).documentItems.length; i++) {
                                if ((documentData as NewDocumentRequest | EditDocumentRequest).documentItems[i].description) {
                                    documentItems.push((documentData as NewDocumentRequest | EditDocumentRequest).documentItems[i]);
                                }
                            }
                            documentItems.forEach((item, orderIndex) => {
                                item.order = orderIndex;
                            });

                            setDocumentData({
                                ...documentData,
                                documentItems: documentItems
                            });
                        }}
                    />
                </Box>
                <Box sx={{display: activeStep === getStepFromName("Deposit Details") ? "block" : "none"}}>
                    <If condition={(documentData as NewDocumentRequest | EditDocumentRequest).documentType == 0}>
                        <>
                            <DepositPaidEditor
                                value={(documentData as NewDocumentRequest | EditDocumentRequest).depositAmount}
                                onChange={value => {
                                    setDocumentData({
                                        ...documentData,
                                        depositAmount: value
                                    });
                                }}
                            />
                            <StepButtons />
                        </>
                    </If>
                    <If condition={(documentData as NewDocumentRequest | EditDocumentRequest).documentType == 2}>
                        <>
                            <DepositToPayEditor
                                value={(documentData as NewDocumentRequest | EditDocumentRequest).depositAmount}
                                date={(documentData as NewDocumentRequest | EditDocumentRequest).depositDueDate}
                                onAmountChange={amount => {
                                    setDocumentData({
                                        ...documentData,
                                        depositAmount: amount
                                    });
                                }}
                                onDateChange={date => {
                                    setDocumentData({
                                        ...documentData,
                                        depositDueDate: date
                                    })
                                }}
                            />
                            <StepButtons
                                disabled={
                                    (documentData as NewDocumentRequest | EditDocumentRequest).depositAmount === undefined ||
                                    ((documentData as NewDocumentRequest | EditDocumentRequest).depositAmount ?? 0) <= 0 ||
                                    (documentData as NewDocumentRequest | EditDocumentRequest).depositDueDate === undefined
                                }
                            />
                        </>
                    </If>
                </Box>
                <Box sx={{display: activeStep === getStepFromName("Summary") ? "block" : "none"}}>
                    <SummaryOverview
                        data={(documentData as NewDocumentRequest | EditDocumentRequest)}
                        skipDocumentType={props.skipDocumentTypeSelector}
                        recipient={recipientData}
                        docTypeCallback={() => setActiveStep(getStepFromName("Document Type"))}
                        docDateCallback={() => setActiveStep(getStepFromName("Reference & Date"))}
                        docRecipientCallback={() => setActiveStep(getStepFromName("Recipient"))}
                        docLineItemCallback={() => setActiveStep(getStepFromName("Line Items"))}
                        docDepositCallback={() => setActiveStep(getStepFromName("Deposit Details"))}
                    />
                    <LastStepButtons/>
                </Box>
            </Box>
        </>
    )
}

export default observer(DocumentEditor);