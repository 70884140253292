import ApiUtil from "../utilities/api-util";
import {ApiResult, EditRecipientRequest, NewRecipientRequest, RecipientResult} from "@mpsinvoices/modelsclient/src";

const ApiUrl = (path: string) => {
    return `Recipients/${path}`;
}

const RecipientApi = {
    findRecipient: (model: {term: string}) => ApiUtil.get<ApiResult<RecipientResult>>(ApiUrl("FindRecipient"), model),
    getRecipient: (model: {id: string}) => ApiUtil.get<ApiResult<EditRecipientRequest>>(ApiUrl("GetRecipient"), model),
    createRecipient: (model: NewRecipientRequest) => ApiUtil.post<ApiResult<RecipientResult>>(ApiUrl("CreateRecipient"), model),
    editRecipient: (model: EditRecipientRequest) => ApiUtil.post<ApiResult<RecipientResult>>(ApiUrl("EditRecipient"), model)
}

export default RecipientApi;