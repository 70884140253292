import dayjs from "dayjs";

export const CurrentDate = () => {
    const date = new Date;
    return dayjs(date).toDate();
}

export const FormatDate = (value: Date) => {
    const date = dayjs(value);
    const result = date.format("D MMMM YYYY");
    return result;
}