import {useRootStore} from "../../../core/stores/root-store";
import {Navigate, useLocation} from "react-router-dom";
import {AppPaths} from "../../../AppPaths";
import {observer} from "mobx-react";

interface PrivateComponentProps {
    children: JSX.Element;
    performRedirect? : boolean;
}

const PrivateComponent: React.FC<PrivateComponentProps> = props => {
    const {authenticationStore} = useRootStore();

    let location = useLocation();
    
    if (!authenticationStore.isLoggedIn) return props.performRedirect ?
        (<Navigate to={AppPaths.Login.Url} state={{from: location}} replace />) : (<></>);

    return props.children;
}

export default observer(PrivateComponent);