import React, {useCallback, useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {MpsTheme} from "./core/utilities/mps-theme";
import {useRootStore} from "./core/stores/root-store";
import MpsSpinner from "./components/shared/mps-spinner";
import {Route, Routes} from "react-router-dom";
import {AppPaths} from "./AppPaths";
import Dashboard from "./components/features/dashboard";
import Login from "./components/features/login";
import LoggedOutLayout from "./components/layouts/logged-out-layout";
import MainLayout from './components/layouts/main-layout';
import NewDocument from "./components/features/documents/new-document";
import ViewDocuments from "./components/features/documents/view-documents";
import EditDocument from "./components/features/documents/edit-document";
import Settings from "./components/features/settings";
import UserAccounts from "./components/features/user-accounts";
import SettingsApi from "./core/api/settings-api";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const {authenticationStore, htmlMergeTagsStore} = useRootStore();

    const initApp = useCallback(async () => {
        if (authenticationStore.token && authenticationStore.refreshToken) {
            await authenticationStore.refreshLogin()
        }
    }, [authenticationStore]);

    useEffect(() => {
        initApp()
            .then(() => {
                if (authenticationStore.isLoggedIn && !htmlMergeTagsStore.hasLoaded) {
                    setIsLoading(true);
                    let promises = [];
                    promises.push(
                        SettingsApi.getHtmlMergeTags()
                            .then(result => {
                                htmlMergeTagsStore.setMergeTags(result.model);
                                htmlMergeTagsStore.setHasLoaded();
                            })
                    );

                    Promise.all(promises)
                        .then(() => {
                            setIsLoading(false);
                        });
                } else {
                    setIsLoading(false);
                }
            });
    }, [initApp, authenticationStore.isLoggedIn]);

    if (isLoading) return <MpsSpinner/>;

    return (
        <>
            <Routes>
                <Route path={AppPaths.Home.Path} element={<MainLayout/>}>
                    <Route path="" element={<Dashboard/>}/>
                </Route>
                <Route path={AppPaths.Login.Path} element={<LoggedOutLayout/>}>
                    <Route path="" element={<Login/>}/>
                </Route>
                <Route path={AppPaths.Settings.Path} element={<MainLayout/>}>
                    <Route path="" element={<Settings/>}/>
                </Route>
                <Route path={AppPaths.UserAccounts.Path} element={<MainLayout/>}>
                    <Route path="" element={<UserAccounts/>}/>
                </Route>
                <Route path={AppPaths.Documents.Path} element={<MainLayout/>}>
                    <Route path={AppPaths.Documents.ViewDocuments.Path} element={<ViewDocuments/>}>
                        <Route path="" element={<ViewDocuments/>}/>
                    </Route>
                    <Route path={AppPaths.Documents.NewDocument.Path} element={<NewDocument/>}/>
                    <Route path={`${AppPaths.Documents.EditDocument.Path}`} element={<EditDocument/>}/>

                </Route>
            </Routes>
        </>
    );
}

export default App;
