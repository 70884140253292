import {useRootStore} from "../../../core/stores/root-store";
import useQuery from "../../../core/utilities/use-query";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    EditDocumentRequest,
} from "@mpsinvoices/modelsclient/src";
import {BusyStates} from "../../../core/utilities/busy-states";
import DocumentApi from "../../../core/api/document-api";
import {toast} from "react-toastify";
import {AppPaths} from "../../../AppPaths";
import BusyIndicator from "../../shared/busy-indicator";
import {observer} from "mobx-react";
import DocumentEditor from "./components/document-editor";

const EditDocument: React.FC = () => {

    const {busyStore} = useRootStore();
    const query = useQuery();
    const navigate = useNavigate();
    const [documentData, setDocumentData] = useState<EditDocumentRequest | null>(null);

    useEffect(() => {
        if (query.get("id")) {
            busyStore.setBusyState(BusyStates.Document);
            DocumentApi.getForEdit({id: query.get("id") ?? ""})
                .then(result => {
                    setDocumentData(result.model);
                })
                .catch(() => {
                    setDocumentData(null);
                })
                .finally(() => {
                    busyStore.removeBusyState(BusyStates.Document);
                })
        } else {
            setDocumentData(null);
        }
    }, [query.get("id")])

    const handleGenerate = () => {
        if (documentData) {
            busyStore.setBusyState(BusyStates.Document);
            DocumentApi.editDocument(documentData)
                .then(result => {
                    let docType = "Quote";
                    switch(documentData.documentType){
                        case 0: docType = "Invoice"; break;
                        case 1: docType = "Quote"; break;
                        case 2: docType = "Deposit Receipt"; break;
                    }
                    toast.success(`${docType} saved successfully!`);
                    navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=${documentData.documentType}&id=${result.model.documentId}`);
                })
                .finally(() => {
                    busyStore.removeBusyState(BusyStates.Document);
                });

        }
    }
    
    if(!documentData) return (
        <>
            <BusyIndicator keyName={BusyStates.Document} />
        </>
    )
    
    return (
        <DocumentEditor
            documentData={documentData}
            setDocumentData={setDocumentData}
            handleGenerate={handleGenerate}
            skipDocumentTypeSelector
        />
    )
}

export default observer(EditDocument);