import {observer} from "mobx-react";
import {Backdrop, Box, CircularProgress, Typography} from "@mui/material";
import If from "../if";

interface MpsSpinnerProps {
    message?: string;
    noMessage?: boolean;
}

const MpsSpinner: React.FC<MpsSpinnerProps> = (
    {
        message = "Loading...",
        ...props
    }) => {
    return (
        <>
            <Backdrop
                open={true}
                sx={{
                    color: "white",
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress color={"inherit"}/>
                    <If condition={props.noMessage !== true}>
                        <Typography variant={"h5"} color={"inherit"}>{message}</Typography>
                    </If>
                </Box>
            </Backdrop>
        </>
    );
}

export default observer(MpsSpinner);