import {useEffect, useRef, useState} from "react";
import {HookProps} from "../types/hook-props";
import {HookReturnValues} from "../types/hook-return-values";
import {PDFDocumentProxy, PDFPageProxy} from "pdfjs-dist";
import {PdfRenderTask} from "../types/pdf-render-task";
import * as pdfjs from 'pdfjs-dist';
import { DocumentInitParameters } from 'pdfjs-dist/types/src/display/api';

function isFunction(value: any): value is Function {
    return typeof value === 'function';
}

export const usePdf = ({
      canvasRef,
      file,
      onDocumentLoadSuccess,
      onDocumentLoadFail,
      onPageLoadSuccess,
      onPageLoadFail,
      onPageRenderSuccess,
      onPageRenderFail,
      scale = 1,
      rotate = 0,
      page = 1,
      cMapUrl,
      cMapPacked,
      workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
      withCredentials = false,
  }: HookProps) : HookReturnValues => {
    const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
    const [pdfPage, setPdfPage] = useState<PDFPageProxy>();
    const renderTask = useRef<PdfRenderTask | null>(null);
    const onDocumentLoadSuccessRef = useRef(onDocumentLoadSuccess);
    const onDocumentLoadFailRef = useRef(onDocumentLoadFail);
    const onPageLoadSuccessRef = useRef(onPageLoadSuccess);
    const onPageLoadFailRef = useRef(onPageLoadFail);
    const onPageRenderSuccessRef = useRef(onPageRenderSuccess);
    const onPageRenderFailRef = useRef(onPageRenderFail);

    // assign callbacks to refs to avoid redrawing
    useEffect(() => {
        onDocumentLoadSuccessRef.current = onDocumentLoadSuccess;
    }, [onDocumentLoadSuccess]);

    useEffect(() => {
        onDocumentLoadFailRef.current = onDocumentLoadFail;
    }, [onDocumentLoadFail]);

    useEffect(() => {
        onPageLoadSuccessRef.current = onPageLoadSuccess;
    }, [onPageLoadSuccess]);

    useEffect(() => {
        onPageLoadFailRef.current = onPageLoadFail;
    }, [onPageLoadFail]);

    useEffect(() => {
        onPageRenderSuccessRef.current = onPageRenderSuccess;
    }, [onPageRenderSuccess]);

    useEffect(() => {
        onPageRenderFailRef.current = onPageRenderFail;
    }, [onPageRenderFail]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
    }, [workerSrc]);

    useEffect(() => {
        const config: DocumentInitParameters = { url: file, withCredentials: withCredentials };
        if (cMapUrl) {
            config.cMapUrl = cMapUrl;
            config.cMapPacked = cMapPacked;
        }

        pdfjs.getDocument(config).promise.then(
            loadedPdfDocument => {
                setPdfDocument(loadedPdfDocument);

                if (isFunction(onDocumentLoadSuccessRef.current)) {
                    onDocumentLoadSuccessRef.current(loadedPdfDocument);
                }
            },
            () => {
                if (isFunction(onDocumentLoadFailRef.current)) {
                    onDocumentLoadFailRef.current();
                }
            }
        );
    }, [file, withCredentials, cMapUrl, cMapPacked]);

    useEffect(() => {
        // draw a page of the pdf
        const drawPDF = (page: PDFPageProxy) => {
            // Because this page's rotation option overwrites pdf default rotation value,
            // calculating page rotation option value from pdf default and this component prop rotate.
            const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
            const dpRatio = 10;
            const adjustedScale = scale * dpRatio;
            const viewport = page.getViewport({ scale: adjustedScale, rotation });
            const canvasEl = canvasRef!.current;
            if (!canvasEl) {
                return;
            }

            const canvasContext = canvasEl.getContext('2d');
            if (!canvasContext) {
                return;
            }

            canvasEl.style.width = `${viewport.width / dpRatio}px`;
            canvasEl.style.height = `${viewport.height / dpRatio}px`;
            canvasEl.height = viewport.height;
            canvasEl.width = viewport.width;

            // if previous render isn't done yet, we cancel it
            if (renderTask.current) {
                renderTask.current.cancel();
                return;
            }

            renderTask.current = page.render({
                canvasContext,
                viewport,
            });

            return renderTask.current.promise.then(
                () => {
                    renderTask.current = null;

                    if (isFunction(onPageRenderSuccessRef.current)) {
                        onPageRenderSuccessRef.current(page);
                    }
                },
                (reason: Error) => {
                    renderTask.current = null;

                    if (reason && reason.name === 'RenderingCancelledException') {
                        drawPDF(page);
                    } else if (isFunction(onPageRenderFailRef.current)) {
                        onPageRenderFailRef.current();
                    }
                }
            );
        };

        if (pdfDocument) {
            pdfDocument.getPage(page).then(
                loadedPdfPage => {
                    setPdfPage(loadedPdfPage);

                    if (isFunction(onPageLoadSuccessRef.current)) {
                        onPageLoadSuccessRef.current(loadedPdfPage);
                    }

                    drawPDF(loadedPdfPage);
                },
                () => {
                    if (isFunction(onPageLoadFailRef.current)) {
                        onPageLoadFailRef.current();
                    }
                }
            );
        }
    }, [canvasRef, page, pdfDocument, rotate, scale]);

    return { pdfDocument, pdfPage };
}