import {observer} from "mobx-react";
import {EmailAddressItemResult, SendEmailRequest} from "@mpsinvoices/modelsclient/src";
import {FormikProps, FormikValues, useField} from "formik";
import {
    Box,
    Chip,
    CircularProgress,
    debounce,
    Menu,
    MenuItem,
    Paper,
    Popper,
    TextField,
    Typography
} from "@mui/material";
import {createRef, useEffect, useMemo, useState} from "react";
import EmailApi from "../../../../core/api/email-api";
import {toast} from "react-toastify";
import If from "../../../shared/if";
import {MpsTheme} from "../../../../core/utilities/mps-theme";
import {Close, Label} from "@mui/icons-material";

interface MailToInputProps {
    formikName: string;
    selectedAddresses: EmailAddressItemResult[];
    onChange: (items: EmailAddressItemResult[]) => void;
    formProps: FormikProps<FormikValues & SendEmailRequest>;
}

const MailToInput: React.FC<MailToInputProps> = props => {
    const [inputValue, setInputValue] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState<EmailAddressItemResult[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [field, meta, helper] = useField({
        name: props.formikName
    });
    const [boxColor, setBoxColor] = useState("rgba(0, 0, 0, 0.23)");
    
    const fetch = useMemo(() => 
        debounce((
            term: string,
            callback: (results: EmailAddressItemResult[]) => void
        ) => {
            setLoading(true);
            EmailApi.searchEmailAddress({term})
                .then(results => {
                    if(results.success){
                        callback(results.model);
                    } else {
                        toast.error("There was a problem search recipients");
                        callback([]);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 400)
    , []);
    
    useEffect(() => {
        let active = true;
        
        if(inputValue === "") {
            setSearchOptions([]);
            return undefined;
        }
        
        fetch(inputValue, (results: EmailAddressItemResult[]) => {
            if(active) {
                setSearchOptions(results);
                setMenuOpen(true);
            }
        });
        
        return () => {
            active = false;
        }
    },[inputValue, fetch]);
    
    useEffect(() => {
        if(meta.error && meta.touched){
            setBoxColor(MpsTheme.palette.error.main);
        } else {
            setBoxColor("rgba(0, 0, 0, 0.23)");
        }
    }, [meta.error, meta.touched]);
    
    const isEmailValid = (email: string) => {
        const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        return regexExp.test(email);
    }

    const handleRemoveEmail = (emailAddressId: string) => {
        let newValues = [...field.value];
        let index = newValues.findIndex((x: string) => x === emailAddressId);
        if(index >= -1){
            newValues.splice(index, 1);
        }
        helper.setValue(newValues);
        
        let newSelectedAddresses = [...props.selectedAddresses];
        let selectedIndex = newSelectedAddresses.findIndex(x => x.emailAddressId === emailAddressId);
        if(index >= -1){
            newSelectedAddresses.splice(selectedIndex, 1);
        }
        props.onChange(newSelectedAddresses);
        helper.setTouched(true);
    }
    
    const handleAddItem = (item: EmailAddressItemResult) => {
        let newValues = [...field.value];
        newValues.push(item.emailAddressId);
        helper.setValue(newValues);
        
        let newSelectedAddresses = [...props.selectedAddresses];
        newSelectedAddresses.push(item);
        props.onChange(newSelectedAddresses);
        
        setInputValue("");
        setMenuOpen(false);
        setAnchorEl(null);
    }
    
    const handeCreateEmail = () => {
        setLoading(true);
        EmailApi.addEmailAddress({emailAddress: inputValue})
            .then(result => {
                handleAddItem(result.model);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    
   

    return (
        <Box sx={{mb: 2}}>
            <Typography variant={"h6"}>TO:</Typography>
            <Box
                sx={{
                    display: "flex", 
                    flexDirection: "row", 
                    gap: 1, 
                    border: `1px solid ${boxColor}`, 
                    borderRadius: "4px",
                    padding: "10px 5px",
                    alignItems: "center"
                }}
            >
                {props.selectedAddresses.map(item => (
                    <Chip
                        key={`mailToItem_${item.emailAddressId}`}
                        label={item.emailAddress}
                        onDelete={() => {handleRemoveEmail(item.emailAddressId)}}
                    />
                ))}
                <Box sx={{flexGrow: 1}}>
                    <TextField
                        variant={"standard"}
                        value={inputValue}
                        onChange={(e) => {
                            setAnchorEl(e.currentTarget);
                            setInputValue(e.target.value);
                            helper.setTouched(true);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                </>
                            )
                        }}
                    />
                </Box>
                <Popper
                    anchorEl={anchorEl}
                    open={menuOpen}
                    placement="bottom-start"
                    sx={{zIndex: 1300}}
                >
                    <Paper sx={{py: 1, pl: 1, pr: 2.5, position: "relative"}}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "2px",
                                right: "2px",
                                zIndex: 10
                            }}
                        >
                            <Close 
                                sx={{cursor: "pointer"}} 
                                onClick={() => {
                                    setMenuOpen(false);
                                    setAnchorEl(null);
                                }} 
                            />
                        </Box>
                        {searchOptions.filter(x => !field.value.some((y: string) => y === x.emailAddressId)).map(item => (
                            <MenuItem
                                key={`searchResultItem_${item.emailAddressId}`}
                                onClick={() => handleAddItem(item)}
                            >
                                {item.emailAddress}
                            </MenuItem>
                        ))}
                        <If condition={!props.selectedAddresses.some(x => x.emailAddress === inputValue.toLowerCase()) && searchOptions.length == 0 && !isEmailValid(inputValue)}>
                            <MenuItem>No email addresses found</MenuItem>
                        </If>
                        <If condition={isEmailValid(inputValue) && !searchOptions.some(x => x.emailAddress === inputValue.toLowerCase())}>
                            <MenuItem
                                onClick={() => handeCreateEmail()}
                            >
                                Add '{inputValue.toLowerCase()}'
                            </MenuItem>
                        </If>
                    </Paper>
                </Popper>
            </Box>
        </Box>
    )
}

export default observer(MailToInput);