import {observer} from "mobx-react";
import {Avatar, Box, Button, Container, Typography} from "@mui/material";
import {MpsTheme} from "../../../core/utilities/mps-theme";
import {LockOutlined} from "@mui/icons-material";
import {UserLoginRequest} from "@mpsinvoices/modelsclient/src";
import MpsForm from "../../shared/mps-form";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import MpsInput from "../../shared/mps-form/components/mps-input";
import {useRootStore} from "../../../core/stores/root-store";
import {BusyStates} from "../../../core/utilities/busy-states";
import {MpsFormErrorHandler} from "../../shared/mps-form/handlers/mps-form-error-handler";
import BusyIndicator from "../../shared/busy-indicator";

const Login: React.FC = () => {
    const {busyStore, authenticationStore} = useRootStore();

    const loginModel: UserLoginRequest = {
        emailAddress: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string().required("Email address is required").email("Email address is not valid"),
        password: Yup.string().required("Password is required")
    });

    const handleSubmit = (values: FormikValues & UserLoginRequest, actions: FormikHelpers<FormikValues & UserLoginRequest>) => {
        busyStore.setBusyState(BusyStates.Login);
        authenticationStore.login(values)
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Login);
            })
    }

    return (
        <>
            <BusyIndicator keyName={BusyStates.Login} />
            <Container component="main" maxWidth={"xs"}>
                <Box
                    sx={{
                        marginTop: 9,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar
                        sx={{
                            m: 1,
                            bgcolor: MpsTheme.palette.primary.main
                        }}
                    >
                        <LockOutlined/>
                    </Avatar>
                    <Typography
                        component={"h1"} variant={"h5"}
                    >
                        Sign In
                    </Typography>
                    <Box sx={{mt: 3, width: "100%"}}>
                        <MpsForm<UserLoginRequest>
                            initialValues={loginModel}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {formProps => (
                                <>
                                    <MpsInput
                                        label={"Email Address"}
                                        required={true}
                                        formikName={"emailAddress"}
                                    />

                                    <MpsInput
                                        label={"Password"}
                                        required={true}
                                        formikName={"password"}
                                        isPassword={true}
                                    />

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        Sign In
                                    </Button>
                                </>
                            )}
                        </MpsForm>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default observer(Login);