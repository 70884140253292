import {useRootStore} from "../../../core/stores/root-store";
import If from "../if";
import MpsSpinner from "../mps-spinner";
import {observer} from "mobx-react";

interface BusyIndicatorProps {
    keyName: string;
}

const BusyIndicator : React.FC<BusyIndicatorProps> = props => {
    const {busyStore} = useRootStore();
    
    return (
        <>
            <If condition={busyStore.isBusy(props.keyName) === true}>
                <MpsSpinner
                    noMessage
                />
            </If>
        </>
    )
}

export default observer(BusyIndicator)