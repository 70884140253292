import {ApiResult, ChangePasswordRequest} from "@mpsinvoices/modelsclient/src";
import ApiUtil from "../utilities/api-util";

const ApiUrl = (path: string) => {
    return  `Profile/${path}`;
}

const ProfileApi = {
    changePassword: (model: ChangePasswordRequest) => ApiUtil.post<ApiResult<boolean>>(ApiUrl("ChangePassword"), model)
}

export default ProfileApi;