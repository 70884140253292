import {createTheme, ThemeOptions} from "@mui/material";
import {blue, orange, teal} from "@mui/material/colors";

const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            light: '#c57c77',
            main: '#B75C55',
            dark: '#80403b',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#3b7b80',
            main: '#55B0B7',
            dark: '#77bfc5',
            contrastText: '#ffffff',
        },
        quote: {
            light: '#4b9fea',
            main: blue[600],
            dark: '#155fa0',
            contrastText: '#ffffff',
        },
        deposit: {
            light: '#33a095',
            main: teal[600],
            dark: '#005f56',
            contrastText: '#ffffff',
        },
        invoice: {
            light: '#fba333',
            main: orange[600],
            dark: '#af6200',
            contrastText: '#000000',
        }
    }
};

export const MpsTheme = createTheme(themeOptions);

declare module "@mui/material/styles" {
    interface Palette {
        quote: Palette["primary"];
        deposit: Palette["primary"];
        invoice: Palette["primary"];
    }
    
    interface PaletteOptions {
        quote?: PaletteOptions["primary"];
        deposit?: PaletteOptions["primary"];
        invoice?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides{
        quote: true;
        deposit: true;
        invoice: true;
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides{
        quote: true;
        deposit: true;
        invoice: true;
    }
}