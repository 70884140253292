import {FormikProps, FormikValues} from "formik";
import {Button} from "@mui/material";
import {observer} from "mobx-react";

interface MpsFormActionsProps<T> {
    formProps: FormikProps<FormikValues & T>;
    onCancelAction: () => void;
    cancelText?: string;
    saveText?: string;
    submitColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const MpsFormActions: <T>(props: MpsFormActionsProps<T>) => React.ReactElement<MpsFormActionsProps<T>> = props => {
    return (
        <>
            <Button
                type='button'
                onClick={props.onCancelAction}
                variant={"outlined"}
            >
                {props.cancelText ?? 'Cancel'}
            </Button>
            <Button
                color={props.submitColor ?? "primary"}
                disabled={!props.formProps.isValid}
                type='submit'
                variant={"contained"}
            >{props.saveText ?? 'Save'}</Button>
        </>
    )
}

export default observer(MpsFormActions);