import {
    alpha,
    AppBar, Autocomplete,
    AutocompleteRenderInputParams,
    Avatar,
    Box, Button,
    Container, Divider,
    IconButton, InputBase,
    ListItemIcon,
    Menu,
    MenuItem, styled,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Download,
    Image,
    Key,
    Logout,
    Menu as MenuIcon,
    People,
    PersonAdd,
    Search as SearchIcon,
    Settings
} from "@mui/icons-material";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {MpsTheme} from "../../../../core/utilities/mps-theme";
import {ReactNode, useState} from "react";
import {AppPaths} from "../../../../AppPaths";
import {useNavigate} from "react-router-dom";
import If from "../../../shared/if";
import ChangePasswordDialog from "./change-password-dialog";
import {DocumentTypes} from "@mpsinvoices/modelsclient/src";
import MpsSearchBox from "./mps-search-box";
import App from "../../../../App";
import DocumentApi from "../../../../core/api/document-api";

const pages = [
    {
        ClassName: "help-target-appbar-new-document",
        Name: "New Quote / Deposit / Quote",
        Location: AppPaths.Documents.NewDocument.Url
    },
    {
        ClassName: undefined,
        Name: "View Quotes",
        Location: `${AppPaths.Documents.ViewDocuments.Url}?docType=1`
    },
    {
        ClassName: undefined,
        Name: "View Deposits",
        Location: `${AppPaths.Documents.ViewDocuments.Url}?docType=2`
    },
    {
        ClassName: undefined,
        Name: "View Invoices",
        Location: `${AppPaths.Documents.ViewDocuments.Url}?docType=0`
    }
];

const MpsAppBar: React.FC = () => {
    const {authenticationStore} = useRootStore();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [anchorElDownload, setAnchorElDownload] = useState<null | HTMLElement>(null);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleOpenDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElDownload(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseDownloadMenu = () => {
        setAnchorElDownload(null);
    };

    return (
        <>
            <AppBar position={"static"} component="nav">
                <Toolbar>
                    <Typography
                        className={"help-appbar-home-link"}
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => {
                            navigate(AppPaths.Home.Url);
                        }}
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'Calibri',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                            textTransform: "uppercase"
                        }}
                    >
                        Mark Property Services
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    className={page.ClassName}
                                    key={page.Name}
                                    onClick={() => {
                                        handleCloseNavMenu();
                                        navigate(page.Location);
                                    }}
                                >
                                    <Typography textAlign="center">{page.Name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}
                    >
                        {pages.map((page) => (
                            <Button
                                color="primary"
                                variant={"contained"}
                                className={page.ClassName}
                                key={page.Name}
                                onClick={() => {
                                    handleCloseNavMenu();
                                    navigate(page.Location);
                                }}
                                sx={{boxShadow: "none"}}
                            >
                                {page.Name}
                            </Button>
                        ))}
                    </Box>
                    <MpsSearchBox/>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip
                            title={"Downloads"}
                            onClick={handleOpenDownloadMenu}
                        >
                            <IconButton
                                color="inherit"
                                size={"small"}
                                sx={{ml:2}}
                            >
                                <Download />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="download-menu"
                            anchorEl={anchorElDownload}
                            open={Boolean(anchorElDownload)}
                            onClose={handleCloseDownloadMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleCloseDownloadMenu();
                                    DocumentApi.getBlankDocumentPdf(1, "Blank-Quote-Template.pdf");
                                }}
                            >
                                Download Blank Quote
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleCloseDownloadMenu();
                                    DocumentApi.getBlankDocumentPdf(2, "Blank-Deposit-Receipt-Template.pdf");
                                }}
                            >
                                Download Blank Deposit Receipt
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleCloseDownloadMenu();
                                    DocumentApi.getBlankDocumentPdf(0, "Blank-Invoice-Template.pdf");
                                }}
                            >
                                Download Blank Invoice
                            </MenuItem>
                        </Menu>
                        <Tooltip
                            title={"Your Account"}
                            onClick={handleOpenUserMenu}
                        >
                            <IconButton
                                color="inherit"
                                size={"small"}
                                sx={{ml: 2}}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: 'white',
                                        color: MpsTheme.palette.primary.main,
                                        width: 32,
                                        height: 32,
                                        fontSize: "1rem"
                                    }}

                                >
                                    {authenticationStore.userInitials}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="account-menu"
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                setShowChangePassword(true);
                            }}>
                                <ListItemIcon>
                                    <Key fontSize="small"/>
                                </ListItemIcon>
                                Change Password
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                authenticationStore.logout();
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                            <Divider/>
                            <MenuItem  onClick={() => {
                                handleCloseUserMenu();
                                navigate(AppPaths.UserAccounts.Url);
                            }}>
                                <ListItemIcon>
                                    <People fontSize="small"/>
                                </ListItemIcon>
                                User Accounts
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                navigate(AppPaths.Settings.Url);
                            }}>
                                <ListItemIcon>
                                    <Settings fontSize="small"/>
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <If condition={showChangePassword}>
                <ChangePasswordDialog handleClose={() => {
                    setShowChangePassword(false);
                }}/>
            </If>
        </>
    )
}

export default observer(MpsAppBar);