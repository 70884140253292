import {HtmlMergeTagsResult} from "@mpsinvoices/modelsclient/src";
import {makeAutoObservable} from "mobx";

export class HtmlMergeTagsStore {
    mergeTags: HtmlMergeTagsResult[] = [];
    hasLoaded: boolean = false;
    
    constructor() {
        makeAutoObservable(this);
    }
    
    setMergeTags(mergeTags: HtmlMergeTagsResult[]){
        this.mergeTags = mergeTags;
    }
    
    setHasLoaded() {
        this.hasLoaded = true;
    }
}