export const SumOfAmounts = (amounts: number[], deposit? : number) => {
    const sumTotal = amounts.reduce((partialSum, a) => partialSum + a, 0);
    if(deposit){
        return CurrencyFormatter(sumTotal - deposit);
    }
    return CurrencyFormatter(sumTotal);
}

export const CurrencyFormatter = (amount: number) => {
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });
    return formatter.format(amount);
}