import {observer} from "mobx-react";
import {Search as SearchIcon} from "@mui/icons-material";
import {
    alpha,
    Autocomplete, AutocompleteChangeReason,
    AutocompleteRenderInputParams, Box,
    Button, Chip,
    CircularProgress, debounce, Grid, Popper, PopperProps,
    styled,
    TextField
} from "@mui/material";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {DocumentListItemResult, RecipientResult} from "@mpsinvoices/modelsclient/src";
import {FormatDate} from "../../../../core/utilities/date-helpers";
import {CurrencyFormatter, SumOfAmounts} from "../../../../core/utilities/number-helpers";
import {AppPaths} from "../../../../AppPaths";
import {useNavigate} from "react-router-dom";
import RecipientApi from "../../../../core/api/recipient-api";
import {toast} from "react-toastify";
import DocumentApi from "../../../../core/api/document-api";
import If from "../../../shared/if";

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    minWidth: 200,
    maxWidth: 200,
    [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
    color: 'inherit',
    '& .MuiAutocomplete-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const StyledPopper = function (props: PopperProps){
    return (<Popper {...props} style={{width: "90vw", maxWidth: "400px"}} placement={"bottom-end"} /> )
}

const MpsSearchBox: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<DocumentListItemResult[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState<DocumentListItemResult | null>(null);
    const navigate = useNavigate();

    const fetch = useMemo(
        () =>
            debounce(
                (
                    term: string,
                    callback: (results: DocumentListItemResult[]) => void
                ) => {
                    setLoading(true);
                    DocumentApi.searchDocuments({term: term})
                        .then(results => {
                            if (results.success) {
                                callback(results.model);
                            } else {
                                toast.error("There was a problem fetching recipients");
                                callback([]);
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (results: DocumentListItemResult[]) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    return (
        <Search>
            <Autocomplete
                className={"help-target-search"}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            placeholder: "Search…"
                        }}
                    />
                )}
                sx={{width: 200}}
                isOptionEqualToValue={(option, value) => option.documentId === value.documentId}
                getOptionLabel={(option) => option.documentReference}
                options={options}
                loading={loading}
                value={value}
                filterSelectedOptions
                filterOptions={(x) => x}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={(event: any, newValue: DocumentListItemResult | null) => {
                    setValue(newValue);
                }}
                noOptionsText={"Search by Job Reference, Recipient Name or Company Name"}
                forcePopupIcon={false}
                PopperComponent={StyledPopper}
                renderOption={(props, option: DocumentListItemResult) => {
                    return (
                        <li {...props} onClick={() => {
                            navigate(`${AppPaths.Documents.ViewDocuments.Url}?docType=${option.documentType}&id=${option.documentId}`);
                            setOptions([]);
                            setInputValue("");
                            setValue(null);
                        }}>
                            <Grid item sx={{wordWrap: 'break-work'}}>
                                <Box component={"div"}>
                                    <If condition={option.documentType === 0}>
                                        <Chip size={"small"} color={"invoice"}
                                              label={"Invoice"} />
                                    </If>
                                    <If condition={option.documentType === 1}>
                                        <Chip size={"small"} color={"quote"}
                                              label={"Quote"} />
                                    </If>
                                    <If condition={option.documentType === 2}>
                                        <Chip size={"small"} color={"deposit"}
                                              label={"Deposit Receipt"} />
                                    </If>
                                </Box>
                                <Box component={"div"} sx={{fontWeight: 'bold'}}
                                     color={"primary"}>{option.documentReference}</Box>
                                <Box component={"div"} sx={{fontWeight: 'bold'}}>{option.recipient}</Box>
                                <Box component={"div"}>{FormatDate(option.documentDate)}</Box>
                                <Box component={"div"} style={{fontSize: "1.5rem"}} color={"secondary"}>{CurrencyFormatter(option.totalAmount)}</Box>
                            </Grid>
                        </li>
                    );
                }}
            />
        </Search>
    )
}

export default observer(MpsSearchBox);