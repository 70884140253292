import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from "history";
import {ToastContainer} from "react-toastify";
import {rootStore, RootStoreContext} from "./core/stores/root-store";
import {MpsTheme} from "./core/utilities/mps-theme";
import {ThemeProvider} from '@mui/material';
import MpsRouter from "./components/shared/mps-router";
import 'react-toastify/dist/ReactToastify.css';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export const history = createBrowserHistory();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <ThemeProvider theme={MpsTheme}>
            <RootStoreContext.Provider value={rootStore}>
                <MpsRouter history={history}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <App/>
                    </LocalizationProvider>
                </MpsRouter>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    limit={5}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </RootStoreContext.Provider>
        </ThemeProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
