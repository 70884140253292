import {DocumentItemRequest, DocumentTypes} from "@mpsinvoices/modelsclient/src";
import {observer} from "mobx-react";
import {
    Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton,
    Paper, styled,
    Table,
    TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import {SumOfAmounts} from "../../../../core/utilities/number-helpers";
import {ArrowDownward, ArrowUpward, Delete} from "@mui/icons-material";
import MpsTextarea from "../../../shared/mps-textarea";

interface LineItemEditorProps {
    value: DocumentItemRequest[];
    totalValue: number;
    totalOverrideValue: boolean;
    documentType: DocumentTypes;
    onChange: (value: DocumentItemRequest[]) => void;
    onTotalOverrideChange: (value: boolean) => void;
    onTotalChange: (value: number) => void;
}

interface NumericFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, NumericFormatCustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);



const NumericFormatCustomLarge = React.forwardRef<NumericFormatProps, NumericFormatCustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                style={{fontSize: 20, width: 200}}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);

const StyledTableCell = styled(TableCell)(({theme}) => ({
    paddingLeft: 5,
    paddingRight: 5,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LineItemEditor: React.FC<LineItemEditorProps> = props => {
    const [data, setData] = useState<DocumentItemRequest[]>([]);

    useEffect(() => {
        setData(props.value);
    }, [props.value]);

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let newValue: DocumentItemRequest[] = [];
        for (let i = 0; i < data.length; i++) {
            if (index === i) {
                newValue.push({
                    description: event.target.value,
                    amount: data[i].amount,
                    order: data[i].order,
                    includeInTotal: (data[i].amount ?? 0) > 0,
                });
            } else {
                newValue.push(data[i]);
            }
        }
        props.onChange(newValue);
    }

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let newValue: DocumentItemRequest[] = [];
        for (let i = 0; i < data.length; i++) {
            if (index === i) {
                newValue.push({
                    description: data[i].description,
                    amount: Number(event.target.value),
                    order: data[i].order,
                    includeInTotal: Number(event.target.value) > 0
                });
            } else {
                newValue.push(data[i]);
            }
        }
        props.onChange(newValue);
    }
    
    const handleTotalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onTotalChange(Number(event.target.value));
    }

    const handleAdd = () => {
        let newValue: DocumentItemRequest[] = [...data];
        if (data.length === 0) {
            newValue.push({
                description: "",
                amount: 0,
                order: 0,
                includeInTotal: false,
            });
        } else {
            newValue.push({
                description: "",
                amount: 0,
                order: Math.max(...data.map(x => x.order)) + 1,
                includeInTotal: false,
            });
        }
        props.onChange(newValue);
    }

    const handleMoveUp = (index: number) => {
        let newData = [...data];
        newData.splice(index - 1, 0, newData.splice(index, 1)[0]);
        newData.forEach((item, orderIndex) => {
            item.order = orderIndex;
        })
        props.onChange(newData);
    }

    const handleMoveDown = (index: number) => {
        let newData = [...data];
        newData.splice(index + 1, 0, newData.splice(index, 1)[0]);
        newData.forEach((item, orderIndex) => {
            item.order = orderIndex;
        })
        props.onChange(newData);
    }

    const handleRemove = (index: number) => {
        let newData = [...data];
        newData.splice(index, 1);
        newData.forEach((item, orderIndex) => {
            item.order = orderIndex;
        })
        props.onChange(newData);
    }

    return (
        <Box sx={{mb: 3}} className={"help-document-lineitem-container"}>
            <Typography variant={"h4"} textAlign={"center"} sx={{mb: 3}}>Enter the items listed on
                the {props.documentType === 0 ? "invoice" : props.documentType === 1 ? "quote" : "deposit"}</Typography>
            <TableContainer sx={{mb: 1, width: 1200, maxWidth: "100%", mx: "auto"}} component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell/>
                            <StyledTableCell sx={{fontWeight: "bold"}}>Description</StyledTableCell>
                            <StyledTableCell sx={{fontWeight: "bold",}} width={150}>Amount</StyledTableCell>
                            <StyledTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <StyledTableRow key={`lineItemRow_${index}`}>
                                <StyledTableCell width={10} sx={{whiteSpace: "nowrap"}}
                                                 className={"help-document-lineitem-move"}>
                                    <IconButton
                                        size={"small"}
                                        color={"success"}
                                        onClick={() => handleMoveUp(index)}
                                        disabled={index === 0}
                                    >
                                        <ArrowUpward/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        color={"success"}
                                        onClick={() => handleMoveDown(index)}
                                        disabled={index === data.length - 1}
                                    >
                                        <ArrowDownward/>
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell className={"help-document-lineitem-descamount"}>
                                    <MpsTextarea
                                        value={item.description}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDescriptionChange(event, index)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell width={150} className={"help-document-lineitem-descamount"}>
                                    <TextField
                                        value={item.amount}
                                        variant={"outlined"}
                                        size={"small"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAmountChange(event, index)}
                                        fullWidth
                                        required
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell width={10} sx={{whiteSpace: "nowrap"}}
                                                 className={"help-document-lineitem-delete"}>
                                    <IconButton
                                        size={"small"}
                                        color={"error"}
                                        onClick={() => handleRemove(index)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box textAlign={"right"} sx={{width: 1200, maxWidth: "100%", mx: "auto", mb: 3}}>
                <Button
                    className={"help-document-lineitem-add"}
                    size={"small"}
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => handleAdd()}
                >
                    Add Item
                </Button>
            </Box>

            <Box style={{display: "flex", justifyContent: "center"}}>
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox
                            checked={props.totalOverrideValue}
                            onChange={() => props.onTotalOverrideChange(!props.totalOverrideValue)}
                        />
                    } label={"Override Total"}
                    />
                </FormGroup>
            </Box>

            {!props.totalOverrideValue ? (
                <Typography textAlign={"center"} variant={"h4"} className={"help-document-lineitem-total"}>
                    <>TOTAL: {SumOfAmounts(data.map(x => x.amount ?? 0))}</>
                </Typography>
            ) : (
                <Box style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10}}>
                    <Typography textAlign={"center"} variant={"h4"} className={"help-document-lineitem-total"}>
                        TOTAL:
                    </Typography>
                    <Box>
                        <TextField
                            value={props.totalValue}
                            variant={"outlined"}
                            size={"medium"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTotalChange(event)}
                            fullWidth
                            required
                            InputProps={{
                                inputComponent: NumericFormatCustomLarge as any
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default observer(LineItemEditor);