import {ApiResult, UserLoginRequest, UserLoginResult } from "@mpsinvoices/modelsclient/src";
import ApiUtil from "../utilities/api-util";

const ApiUrl = (path: string) => {
    return  `Authentication/${path}`;
}

const AuthenticationApi = {
    login: (model: UserLoginRequest) => ApiUtil.post<ApiResult<UserLoginResult>>(ApiUrl("Login"), model),
    logout: () => ApiUtil.post(ApiUrl("Logout")),
    refreshToken: () => ApiUtil.post<ApiResult<UserLoginResult>>(ApiUrl("RefreshToken"))
}

export default AuthenticationApi;