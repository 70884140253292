interface MpsFormErrorHandlerProps<T> {
    error: any,
    actions: any
}

export function MpsFormErrorHandler<T>({error, actions}: MpsFormErrorHandlerProps<T>) {
    if(error.errorMessages && error.errorMessages.length > 0){
        error.errorMessages.forEach((item: any) => {
            if (item.property) {
                actions.setFieldError(item.property, item.message);
            } else {
                actions.setErrors({error: item.message});
            }
        })
    } else {
        actions.setErrors({error: "The was a problem processing your request."});
    }
}