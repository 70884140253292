import {observer} from "mobx-react";
import {Editor} from "@tinymce/tinymce-react";
import {useState} from "react";
import {useRootStore} from "../../../core/stores/root-store";

interface MpsHtmlEditorProps {
    initialValue? : string;
    onValueChange? : (value: string) => void
    minimalControls? : boolean;
    height? : number;
    disabled?: boolean;
    disabledMergeFields?: boolean;
}

const MpsHtmlEditor : React.FC<MpsHtmlEditorProps> = props => {
    const {htmlMergeTagsStore} = useRootStore();
    
    const [value, setValue] = useState<string>(props.initialValue ?? "");


    const handleEditorChange = (content: string, editor: any) => {
        if(props.onValueChange){
            props.onValueChange(content);
        }
    }

    const minPlugins = "autolink link charmap fullscreen mergefields";
    const fullPlugins = "advlist autolink lists link charmap code fullscreen table mergefields";

    const minToolbar = "undo redo | cut copy paste | " +
        "styles | bold italic underline | alignleft aligncenter alignright alignjustify | " +
        `charmap | ${props.disabledMergeFields === true ? "" : "mergefields |"} code fullscreen`;

    const fullToolbar = [
        "undo redo | cut copy paste | styles | bold italic underline | alignleft aligncenter alignright alignjustify",
        `bullist numlist outdent indent | table link charmap | ${props.disabledMergeFields === true ? "" : "mergefields |"} code fullscreen`];

    return (
        <Editor
            tinymceScriptSrc={"/lib/tinymce/tinymce.min.js"}
            initialValue={value}
            init={{
                height: props.height ?? 500,
                width: "100%",
                skin: "tinymce-5",
                resize: false,
                statusbar: false,
                menubar: false,
                plugins: props.minimalControls === true ? minPlugins : fullPlugins,
                toolbar: props.minimalControls === true ? minToolbar : fullToolbar,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                mergefields_list: htmlMergeTagsStore.mergeTags,
            }}
            onEditorChange={handleEditorChange}
            disabled={props.disabled}
        />
    );
}

export default observer(MpsHtmlEditor)