import {AuthenticationStore} from "./authentication-store";
import {createContext, useContext} from "react";
import {BusyStore} from "./busy-store";
import {HtmlMergeTagsStore} from "./html-merge-tags-store";

interface RootStore{
    authenticationStore: AuthenticationStore;
    busyStore: BusyStore;
    htmlMergeTagsStore: HtmlMergeTagsStore;
}

export const rootStore: RootStore = {
    authenticationStore: new AuthenticationStore(),
    busyStore: new BusyStore(),
    htmlMergeTagsStore: new HtmlMergeTagsStore()
}

export const RootStoreContext = createContext(rootStore);

export function useRootStore() {
    return useContext(RootStoreContext);
}