import {useLayoutEffect, useState} from "react";
import {Router} from "react-router-dom";

interface MpsRouterProps {
    children: string | JSX.Element | JSX.Element[];
    history: any;
}

const MpsRouter : React.FC<MpsRouterProps> = props => {
    const [state, setState] = useState({
        action: props.history.action,
        location: props.history.location,
    });

    useLayoutEffect(() => props.history.listen(setState), [props.history]);

    return (
        <Router
            children={props.children}
            location={state.location}
            navigationType={state.action}
            navigator={props.history}
        />
    );
}

export default MpsRouter;