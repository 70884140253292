import {Form, Formik, FormikHelpers, FormikProps, FormikValues, useFormikContext} from "formik";
import { useEffect } from "react";
import {observer} from "mobx-react";
import {Alert} from "@mui/material";

interface MpsFormProps<T> {
    innerRef?: React.Ref<FormikProps<FormikValues & T>>
    initialValues: FormikValues & T;
    onSubmit: (values: FormikValues & T, actions: FormikHelpers<FormikValues & T>) => void;
    children: ( props: FormikProps<FormikValues & T>) => React.ReactNode
    validationSchema?: any;
    validationCallback?: (isValid: boolean) => void;
    fullHeight?: boolean
}

function MpsForm<T> (props: MpsFormProps<T>) {
    const ValidationCallBack = () => {
        const {isValid} = useFormikContext();

        useEffect(() => {
            if(props.validationCallback){
                props.validationCallback(isValid);
            }
        }, [isValid])
        return null;
    }
    
    return (
        <Formik
            innerRef={props.innerRef}
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            onSubmit={props.onSubmit}
            auto={true}
        >
            {(formikProps: FormikProps<FormikValues & T>) => (
                <>
                    <Form style={{width: "100%", height: props.fullHeight ? "100%" : undefined}} autoComplete="off">
                        {formikProps.errors.error ?
                            <Alert severity="error" sx={{mb: 2}}>{formikProps.errors.error.toString()}</Alert> : null}
                        {props.children(formikProps)}
                        <ValidationCallBack />
                    </Form>
                </>
            )}
        </Formik>
    )
}

export default observer(MpsForm);