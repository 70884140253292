import {observer} from "mobx-react";
import {
    Autocomplete,
    Box,
    Button, Card, CardActions, CardContent,
    CircularProgress,
    debounce,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {DocumentTypes, EditRecipientRequest, NewRecipientRequest, RecipientResult} from "@mpsinvoices/modelsclient/src";
import {useCallback, useEffect, useMemo, useState} from "react";
import RecipientApi from "../../../../core/api/recipient-api";
import {toast} from "react-toastify";
import If from "../../../shared/if";
import MpsForm from "../../../shared/mps-form";
import {FormikHelpers, FormikValues, setIn} from "formik";
import * as Yup from "yup";
import MpsInput from "../../../shared/mps-form/components/mps-input";
import MpsFormActions from "../../../shared/mps-form/components/mps-form-actions";
import {useRootStore} from "../../../../core/stores/root-store";
import {BusyStates} from "../../../../core/utilities/busy-states";
import {MpsFormErrorHandler} from "../../../shared/mps-form/handlers/mps-form-error-handler";

interface RecipientSelectorProps {
    value: string;
    onChange: (value: string) => void;
    documentType: DocumentTypes;
    callback?: (value: RecipientResult | null) => void;
}

const RecipientSelector: React.FC<RecipientSelectorProps> = props => {
    const {busyStore} = useRootStore();

    const [value, setValue] = useState<RecipientResult | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<RecipientResult[]>([]);
    const [loading, setLoading] = useState(false);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editModel, setEditModel] = useState<EditRecipientRequest | null>(null);
    const [initialLoad, setInitialLoad] = useState(false);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    term: string,
                    callback: (results: RecipientResult[]) => void
                ) => {
                    setLoading(true);
                    RecipientApi.findRecipient({term: term})
                        .then(results => {
                            if (results.success) {
                                callback(results.model);
                            } else {
                                toast.error("There was a problem fetching recipients");
                                callback([]);
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                },
                400
            ),
        []
    );

    const initialFetch = useCallback(async () => {
        if (props.value) {
            let promises = [];
            const loader = RecipientApi.getRecipient({id: props.value})
                .then(result => {
                    setEditModel(result.model);
                    console.log(result.model.recipientName);
                    console.log(result.model.companyName);
                    var item : RecipientResult = {
                        recipientId: result.model.recipientId,
                        name: result.model.recipientName,
                        address: result.model.streetAddress,
                        phoneNumber: result.model.phoneNumber,
                        emailAddress: ""
                    }
                    if(result.model.recipientName && result.model.companyName){
                        item.name = `${result.model.recipientName} (${result.model.companyName})`
                    }
                    if(result.model.recipientName && !result.model.companyName){
                        item.name = result.model.recipientName
                    }
                    if(!result.model.recipientName && result.model.companyName){
                        item.name = result.model.companyName
                    }
                    setValue(item);
                })
                .catch(error => {
                    console.log(error);
                });
            promises.push(loader);
            await Promise.all(promises);
        }
        setInitialLoad(true);
    }, [props.value]);

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (results: RecipientResult[]) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, initialFetch]);

    useEffect(() => {
        if (!initialLoad) {
            initialFetch().then(() => {
            });
        }
    }, [])

    useEffect(() => {
        if (value && initialLoad) {
            props.onChange(value.recipientId);
            RecipientApi.getRecipient({id: value.recipientId})
                .then(result => {
                    setEditModel(result.model);
                })
                .catch(error => {
                    console.log(error);
                    setEditModel(null);
                });
        } else {
            setEditModel(null);
        }
        if (props.callback) {
            props.callback(value);
        }
    }, [value])

    const handleNewSubmit = (values: (FormikValues & NewRecipientRequest), actions: FormikHelpers<FormikValues & NewRecipientRequest>) => {
        busyStore.setBusyState(BusyStates.Document);
        RecipientApi.createRecipient(values)
            .then((result) => {
                setValue(result.model);
                setNewDialogOpen(false);
                actions.resetForm();
                toast.success("Recipient added successfully!")
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Document);
            });
    }

    const newInitialValues: NewRecipientRequest = {
        companyName: "",
        recipientName: "",
        streetAddress: "",
        town: "",
        county: "",
        postalCode: "",
        phoneNumber: "",
        emailAddress: ""
    };

    const newValidationSchema = Yup.object().shape({
        companyName: Yup.string(),
        recipientName: Yup.string(),
        streetAddress: Yup.string().required("Street Address is required"),
        town: Yup.string().required("Town is required"),
        county: Yup.string(),
        postalCode: Yup.string().required("Postal Code is required"),
        phoneNumber: Yup.string(),
    });

    const handleEditSubmit = (values: (FormikValues & EditRecipientRequest), actions: FormikHelpers<FormikValues & EditRecipientRequest>) => {
        busyStore.setBusyState(BusyStates.Document);
        RecipientApi.editRecipient(values)
            .then((result) => {
                setValue(result.model);
                setEditDialogOpen(false);
                setEditModel(values);
                actions.resetForm();
                toast.success("Recipient successfully saved!")
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Document);
            });
    }

    const editInitialValues: EditRecipientRequest = {
        recipientId: "",
        companyName: "",
        recipientName: "",
        streetAddress: "",
        town: "",
        county: "",
        postalCode: "",
        phoneNumber: "",
        emailAddress: ""
    }

    const editValidationSchema = Yup.object().shape({
        companyName: Yup.string(),
        recipientName: Yup.string(),
        streetAddress: Yup.string().required("Street Address is required"),
        town: Yup.string().required("Town is required"),
        county: Yup.string(),
        postalCode: Yup.string().required("Postal Code is required"),
        phoneNumber: Yup.string(),
        emailAddress: Yup.string(),
    });

    return (
        <>
            <Grid sx={{display: "flex", flexDirection: "column", alignItems: "center", mb: 3}}>
                <Typography variant={"h4"} textAlign={"center"} sx={{mb: 3}}>Who is
                    the {props.documentType === 0 ? "invoice" : props.documentType === 1 ? "quote" : "deposit"} for?</Typography>
                <Autocomplete
                    className={"help-document-recipient"}
                    sx={{width: 300, mb: 3}}
                    isOptionEqualToValue={(option, value) => option.recipientId === value.recipientId}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    filterSelectedOptions
                    value={value}
                    filterOptions={(x) => x}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(event: any, newValue: RecipientResult | null) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    noOptionsText={
                        <Button
                            size={"small"}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => setNewDialogOpen(true)}
                            fullWidth
                        >
                            Create new recipient
                        </Button>
                    }
                    renderOption={(props, option: RecipientResult) => {
                        return (
                            <li {...props}>
                                <Grid item sx={{wordWrap: 'break-word'}}>
                                    <Box component={"div"} sx={{fontWeight: 'bold'}}>{option.name}</Box>
                                    <Typography variant={"body2"}>
                                        {option.address}
                                    </Typography>
                                    <If condition={option.phoneNumber !== undefined && option.phoneNumber !== "" && option.phoneNumber !== null}>
                                        <Typography variant={"body2"}>
                                            {option.phoneNumber}
                                        </Typography>
                                    </If>
                                    <If condition={option.emailAddress !== undefined && option.emailAddress !== "" && option.emailAddress !== null}>
                                        <Typography variant={"body2"}>
                                            {option.emailAddress}
                                        </Typography>
                                    </If>
                                </Grid>
                            </li>
                        );
                    }}
                />

                <If condition={value !== null}>
                    <Card sx={{minWidth: 300}}>
                        <CardContent>
                            <Typography variant={"h5"} component={"div"}>{value?.name}</Typography>
                            {value?.address.split(",").map((value) => (
                                <Typography variant={"body2"} key={value.trim()}>
                                    {value.trim()}
                                </Typography>
                            ))}

                            <If condition={value?.phoneNumber !== undefined && value?.phoneNumber !== "" && value?.phoneNumber !== null}>
                                <Typography variant={"body2"}>
                                    {value?.phoneNumber}
                                </Typography>
                            </If>

                            <If condition={value?.emailAddress !== undefined && value?.emailAddress !== "" && value?.emailAddress !== null}>
                                <Typography variant={"body2"}>
                                    {value?.emailAddress}
                                </Typography>
                            </If>
                        </CardContent>
                        <If condition={editModel !== null}>
                            <CardActions>
                                <Button
                                    className={"help-document-recipient-edit"}
                                    onClick={() => setEditDialogOpen(true)}
                                >
                                    Edit Recipient
                                </Button>
                            </CardActions>
                        </If>
                    </Card>
                </If>
            </Grid>

            <Dialog open={newDialogOpen} scroll={"paper"}>
                <DialogTitle>New Recipient</DialogTitle>
                <MpsForm<NewRecipientRequest>
                    initialValues={newInitialValues}
                    validationSchema={newValidationSchema}
                    onSubmit={handleNewSubmit}
                >
                    {formProps => (
                        <>
                            <>
                                <DialogContent>
                                    <Box sx={{pt: 2}}>
                                        <MpsInput
                                            label={"Company Name"}
                                            required={formProps.values.recipientName === ""}
                                            formikName={"companyName"}
                                        />
                                        <MpsInput
                                            label={"Recipient Name"}
                                            required={formProps.values.companyName === ""}
                                            formikName={"recipientName"}
                                        />
                                        <MpsInput
                                            label={"Street Address"}
                                            required={true}
                                            formikName={"streetAddress"}
                                        />
                                        <MpsInput
                                            label={"Town"}
                                            required={true}
                                            formikName={"town"}
                                        />
                                        <MpsInput
                                            label={"County"}
                                            required={false}
                                            formikName={"county"}
                                        />
                                        <MpsInput
                                            label={"Postal Code"}
                                            required={true}
                                            formikName={"postalCode"}
                                        />
                                        <MpsInput
                                            label={"Phone Number"}
                                            required={false}
                                            formikName={"phoneNumber"}
                                        />
                                        <MpsInput
                                            label={"Email Address"}
                                            required={false}
                                            formikName={"emailAddress"}
                                        />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <MpsFormActions
                                        formProps={formProps}
                                        onCancelAction={() => {
                                            setNewDialogOpen(false);
                                            formProps.resetForm();
                                        }}
                                        saveText={"Add Recipient"}
                                    />
                                </DialogActions>
                            </>
                        </>
                    )}
                </MpsForm>
            </Dialog>

            <Dialog open={editDialogOpen} scroll={"paper"}>
                <DialogTitle>Edit Recipient</DialogTitle>
                <MpsForm<EditRecipientRequest>
                    initialValues={editModel ?? editInitialValues}
                    validationSchema={editValidationSchema}
                    onSubmit={handleEditSubmit}
                >
                    {formProps => (
                        <>
                            <>
                                <DialogContent>
                                    <Box sx={{pt: 2}}>
                                        <MpsInput
                                            label={"Company Name"}
                                            required={formProps.values.recipientName === ""}
                                            formikName={"companyName"}
                                        />
                                        <MpsInput
                                            label={"Recipient Name"}
                                            required={formProps.values.companyName === ""}
                                            formikName={"recipientName"}
                                        />
                                        <MpsInput
                                            label={"Street Address"}
                                            required={true}
                                            formikName={"streetAddress"}
                                        />
                                        <MpsInput
                                            label={"Town"}
                                            required={true}
                                            formikName={"town"}
                                        />
                                        <MpsInput
                                            label={"County"}
                                            required={false}
                                            formikName={"county"}
                                        />
                                        <MpsInput
                                            label={"Postal Code"}
                                            required={true}
                                            formikName={"postalCode"}
                                        />
                                        <MpsInput
                                            label={"Phone Number"}
                                            required={false}
                                            formikName={"phoneNumber"}
                                        />
                                        <MpsInput
                                            label={"Email Address"}
                                            required={false}
                                            formikName={"emailAddress"}
                                        />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <MpsFormActions
                                        formProps={formProps}
                                        onCancelAction={() => {
                                            setEditDialogOpen(false);
                                            formProps.resetForm();
                                        }}
                                        saveText={"Save Recipient"}
                                    />
                                </DialogActions>
                            </>
                        </>
                    )}
                </MpsForm>
            </Dialog>
        </>
    )
}

export default observer(RecipientSelector);