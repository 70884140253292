import {observer} from "mobx-react";
import {useEffect, useRef, useState} from "react";
import styles from "./index.module.scss";
import {usePdf} from "./hooks/use-pdf";
import If from "../if";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import printJS from "print-js";
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import {ClassNames} from "../../../core/utilities/class-names";
import { isMobile } from "react-device-detect";

interface MpsPdfViewerProps {
    file: string;
    downloadName: string;
}

const MpsPdfViewer: React.FC<MpsPdfViewerProps> = props => {
    const [page, setPage] = useState(1);
    const [scale, setScale] = useState(1);
    const canvasRef = useRef(null);
    const [pageInputValue, setPageInputValue] = useState<string>("");
    
    const pdfScales = [0.5,0.75,1,1.5,2,2.5,3,4,5];

    const navigate = useNavigate();

    const {pdfDocument, pdfPage} = usePdf({
        file: props.file,
        page,
        canvasRef,
        scale
    });

    useEffect(() => {
        setPageInputValue(page.toString());
    }, [page]);

    if (!pdfDocument) return null;

    const handleInputPageChange = () => {
        if (!isNaN(+pageInputValue)) {
            let value = +pageInputValue;
            if (value > pdfDocument.numPages) {
                value = pdfDocument.numPages;
            }
            if (value < 1) {
                value = 1;
            }
            setPage(value);
        }
    }

    const handleDownload = () => {
        console.log(props.file);
        axios
            .get(props.file, {
                responseType: 'blob',
                baseURL: ""
            })
            .then((res) => {
                fileDownload(res.data, `${props.downloadName}.pdf`);
            });
    }
    
    const handlePrint = () => {
        printJS(props.file);
    }
    
    const handleZoomIn = () => {
        const zoomIndex = pdfScales.indexOf(scale);
        if (zoomIndex === pdfScales.length - 1) return;
        setScale( pdfScales[zoomIndex + 1]);
    }
    
    const handleZoomOut = () => {
        const zoomIndex = pdfScales.indexOf(scale);
        if (zoomIndex === 0) return;
        setScale(pdfScales[zoomIndex - 1]);
    }

    return (
        <div className={styles.PdfContainer}>
            <div className={styles.PdfNavBar}>
                <div className={styles.PdfNavBarStart}>
                    
                </div>
                <div className={styles.PdfNavBarMiddle}>
                    <div>
                        <button
                            className={styles.PdfNavButton}
                            type={"button"}
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            &lt;
                        </button>
                    </div>
                    <If condition={pdfDocument.numPages <= 1}>
                        <span>Page {page} of {pdfDocument.numPages}</span>
                    </If>
                    <If condition={pdfDocument.numPages > 1}>
                        <div>
                            <span>Page</span>
                            <input
                                className={styles.PdfPageNumberInput}
                                min={1}
                                max={pdfDocument.numPages}
                                step={1}
                                value={pageInputValue}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        handleInputPageChange();
                                    }
                                }}
                                onChange={e => {
                                    setPageInputValue(e.target.value);
                                }}
                            />
                            <span>of {pdfDocument.numPages}</span>
                        </div>
                    </If>
                    <div>
                        <button
                            className={styles.PdfNavButton}
                            type={"button"}
                            disabled={page === pdfDocument!.numPages}
                            onClick={() => setPage(page + 1)}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
                <div className={styles.PdfNavBarEnd}>
                    <button
                        className={styles.PdfNavButton}
                        type={"button"}
                        onClick={() => handleDownload()}
                    >
                        <SaveIcon />
                    </button>
                    <button
                        className={ClassNames([styles.PdfNavButton, isMobile ? styles.PdfHideOnMobile : ""])}
                        type={"button"}
                        onClick={() => handlePrint()}
                    >
                        <PrintIcon />
                    </button>
                </div>
            </div>
            <div className={styles.PdfViewer}>
                <div className={styles.PdfWrapper}>
                    <canvas style={{backgroundColor: 'white'}} ref={canvasRef}/>
                </div>
                <div className={styles.PdfZoomControls}>
                    <button
                        type={"button"}
                        className={styles.PdfZoomOut}
                        onClick={() => handleZoomOut()}
                        disabled={pdfScales.indexOf(scale) === 0}
                    >
                        &minus;
                    </button>
                    <button
                        type={"button"}
                        className={styles.PdfZoomIn}
                        onClick={() => handleZoomIn()}
                        disabled={pdfScales.indexOf(scale) === pdfScales.length - 1}
                    >
                        &#43;
                    </button>
                </div>
            </div>


        </div>
    )
}

export default observer(MpsPdfViewer);