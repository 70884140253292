import {observer} from "mobx-react";
import {Outlet} from "react-router-dom";
import PrivateComponent from "../../shared/private-component";
import MpsAppBar from "./components/mps-app-bar";
import {Container, CssBaseline} from "@mui/material";
import MpsHelp from "../../features/mps-help";

const MainLayout: React.FC = () => {
    return (
        <PrivateComponent performRedirect={true}>
            <>
                <div className={"help-main-container"}>
                    <CssBaseline/>
                    <MpsAppBar/>
                    <Container maxWidth={"lg"} sx={{py: 3}}>
                        <Outlet/>
                    </Container>
                </div>
            </>
        </PrivateComponent>
    );
}

export default observer(MainLayout);