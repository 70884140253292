import ApiUtil from "../utilities/api-util";
import {ApiResult, EditSettingsRequest, HtmlMergeTagsResult} from "@mpsinvoices/modelsclient/src";

const ApiUrl = (path: string) => {
    return `Settings/${path}`;
}

const SettingsApi = {
    getSettings: () => ApiUtil.get<ApiResult<EditSettingsRequest>>(ApiUrl("GetSettings")),
    saveSettings: (model: EditSettingsRequest) => ApiUtil.post<ApiResult<Boolean>>(ApiUrl("SaveSettings"), model),
    getHtmlMergeTags: () => ApiUtil.get<ApiResult<HtmlMergeTagsResult[]>>(ApiUrl("GetHtmlMergeTags"))
}

export default SettingsApi