import {useField} from "formik";
import {TextField, Typography} from "@mui/material";
import {observer} from "mobx-react";

interface MpsInputProps {
    label: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    formikName: string;
    helperText?: string;
    isPassword?: boolean;
}

const MpsInput : React.FC<MpsInputProps> = props => {
    const [field, meta,] = useField(
        {
            name: props.formikName
        });
    
    return (
        <>
            <TextField
                sx={{mb: 2}}
                autoComplete="off"
                required={props.required}
                disabled={props.disabled}
                label={props.label}
                placeholder={props.placeholder}
                helperText={<Typography color={"error"} variant={"body2"}>{meta.error}</Typography>}
                variant={"outlined"}
                fullWidth
                type={props.isPassword === true ? "password" : undefined}
                {...field}
            />
        </>
    )
}

export default observer(MpsInput);