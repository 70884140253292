import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {EditUserRequest, NewUserRequest, UserListItemResult} from "@mpsinvoices/modelsclient/src";
import {useRootStore} from "../../../core/stores/root-store";
import {BusyStates} from "../../../core/utilities/busy-states";
import UsersApi from "../../../core/api/users-api";
import BusyIndicator from "../../shared/busy-indicator";
import {
    Container,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    IconButton, styled, tableCellClasses, DialogTitle, Dialog, Box, DialogContent, DialogActions
} from "@mui/material";
import {Edit, PersonAdd} from "@mui/icons-material";
import * as Yup from "yup";
import MpsForm from "../../shared/mps-form";
import {FormikHelpers, FormikValues} from "formik";
import MpsInput from "../../shared/mps-form/components/mps-input";
import MpsFormActions from "../../shared/mps-form/components/mps-form-actions";
import {toast} from "react-toastify";
import {MpsFormErrorHandler} from "../../shared/mps-form/handlers/mps-form-error-handler";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: 5,
    paddingRight: 5,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const UserAccounts: React.FC = () => {
    const {busyStore} = useRootStore();
    const [data, setData] = useState<UserListItemResult[]>([]);
    const [editData, setEditData] = useState<EditUserRequest | null>(null);
    const [showAdd, setShowAdd] = useState(false);

    const loadData = () => {
        busyStore.setBusyState(BusyStates.UserAccounts);
        UsersApi.getUsers()
            .then(results => {
                setData(results.model);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserAccounts);
            })
    }

    useEffect(() => {
        loadData();
    }, []);
    
    const editValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        password: Yup.string()
    });
    
    const onEditSubmit = (values: (FormikValues & EditUserRequest), actions: FormikHelpers<FormikValues & EditUserRequest>) => {
        busyStore.setBusyState(BusyStates.UserAccounts);
        UsersApi.editUser(values)
            .then(() => {
                toast.success("User saved successfully!");
                setEditData(null);
                loadData();
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserAccounts);
            });
    }
    
    const addInitialValues : NewUserRequest = {
        name: "",
        emailAddress: "",
        password: ""
    };
    
    const addValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        emailAddress: Yup.string().required("Email address is required"),
        password: Yup.string().required("Password is required")
    });

    const onAddSubmit = (values: (FormikValues & NewUserRequest), actions: FormikHelpers<FormikValues & NewUserRequest>) => {
        busyStore.setBusyState(BusyStates.UserAccounts);
        UsersApi.createUser(values)
            .then(() => {
                toast.success("User created successfully!");
                setShowAdd(false);
                actions.resetForm();
                loadData();
            })
            .catch(error => {
                MpsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserAccounts);
            });
    }

    return (
        <>
            <BusyIndicator keyName={BusyStates.UserAccounts}/>
            <Box sx={{textAlign: "right", mb: 3}}>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    startIcon={<PersonAdd />}
                    onClick={() => setShowAdd(true)}
                >
                    Create User
                </Button>                    
            </Box>
            <Container maxWidth={"sm"}>
                <TableContainer>
                    <Table sx={{width: "100%"}} size="small">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell width={10}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(item => (
                                <StyledTableRow key={item.userId}>
                                    <StyledTableCell>{item.name}</StyledTableCell>
                                    <StyledTableCell>{item.emailAddress}</StyledTableCell>
                                    <StyledTableCell
                                        sx={{
                                            whiteSpace: "nowrap",
                                            textAlign: "right"
                                        }}
                                        width={10}
                                    >
                                        <IconButton
                                            size={"small"}
                                            color={"secondary"}
                                            onClick={() => {
                                                setEditData({
                                                    userId: item.userId,
                                                    name: item.name,
                                                    password: ""
                                                });
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            {!editData ? null : (
                <Dialog open={true}>
                    <DialogTitle>Edit User</DialogTitle>
                    <MpsForm<EditUserRequest>
                        initialValues={editData}
                        validationSchema={editValidationSchema}
                        onSubmit={onEditSubmit}
                    >
                        {formProps => (
                            <>
                                <DialogContent>
                                    <Box sx={{pt: 2}}>
                                        <MpsInput
                                            label={"Name"}
                                            required
                                            formikName={"name"}
                                        />
                                        <MpsInput
                                            label={"Password"}
                                            isPassword
                                            formikName={"password"}
                                        />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <MpsFormActions
                                        formProps={formProps}
                                        onCancelAction={() => {
                                            setEditData(null);
                                        }}
                                        saveText={"Save User"}
                                    />
                                </DialogActions>
                            </>
                        )}
                    </MpsForm>
                </Dialog>
            )}
            
            <Dialog open={showAdd}>
                <DialogTitle>Create User</DialogTitle>
                <MpsForm<NewUserRequest>
                    initialValues={addInitialValues}
                    validationSchema={addValidationSchema}
                    onSubmit={onAddSubmit}
                >
                    {formProps => (
                        <>
                            <DialogContent>
                                <Box sx={{pt: 2}}>
                                    <MpsInput
                                        label={"Name"}
                                        required
                                        formikName={"name"}
                                    />
                                    <MpsInput
                                        label={"Email Address"}
                                        required
                                        formikName={"emailAddress"}
                                    />
                                    <MpsInput
                                        label={"Password"}
                                        isPassword
                                        required
                                        formikName={"password"}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <MpsFormActions
                                    formProps={formProps}
                                    onCancelAction={() => {
                                        setShowAdd(false);
                                        formProps.resetForm();
                                    }}
                                    saveText={"Create User"}
                                />
                            </DialogActions>
                        </>
                    )}
                </MpsForm>
            </Dialog>
        </>
    )
}

export default observer(UserAccounts);