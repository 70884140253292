import {CSSProperties} from "react";
import {useField} from "formik";
import MpsHtmlEditor from "../../mps-html-editor";
import {observer} from "mobx-react";
import {Box, FormControl, InputLabel} from "@mui/material";

interface MpsHtmlInputProps {
    label: string;
    required?: boolean;
    disabled?: boolean;
    formikName: string;
    style?: CSSProperties;
    minimalControls?: boolean;
    height?: number;
    disabledMergeFields?: boolean;
}

const MpsHtmlInput: React.FC<MpsHtmlInputProps> = props => {
    const [field, meta, helpers] = useField(
        {
            name: props.formikName
        });

    const handleOnChange = (value: string) => {
        helpers.setTouched(true);
        helpers.setValue(value ?? "");
    }

    return (
        <Box sx={{mb: 2}}>
            <InputLabel>{props.label}</InputLabel>
            <MpsHtmlEditor
                initialValue={field.value}
                onValueChange={handleOnChange}
                disabled={props.disabled}
                height={props.height}
                minimalControls={props.minimalControls}
                disabledMergeFields={props.disabledMergeFields}
            />
        </Box>
    )
}

export default observer(MpsHtmlInput);