export const ClassNames = (classes: string[]) => {
    let resultClasses: string[] = [];

    classes.forEach(value => {
        if (value) resultClasses.push(value);
    });

    if(resultClasses.length === 0) return "";

    return resultClasses.join(" ");
}