import {observer} from "mobx-react";
import {Grid, Typography} from "@mui/material";
import {StaticDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {DocumentTypes} from "@mpsinvoices/modelsclient/src";

interface DateSelector {
    value: Date,
    onChange: (value: Date) => void;
}

const DateSelector: React.FC<DateSelector> = props => {
    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
            >
                <StaticDatePicker
                    className={"help-document-date-selector"}
                    value={dayjs(props.value)}
                    onChange={newValue => {
                        if (newValue !== null) {
                            props.onChange(newValue.toDate())
                        }
                    }}
                    slotProps={
                        {
                            actionBar: {
                                actions: []
                            },
                            toolbar: {
                                hidden: true
                            }
                        }
                    }
                />
            </Grid>
        </>
    )
}

export default observer(DateSelector);