import {observer} from "mobx-react";
import {useField} from "formik";
import {useEffect, useState} from "react";
import {MpsTheme} from "../../../../core/utilities/mps-theme";
import {Box, TextField, Typography} from "@mui/material";

interface MailSubjectInputProps {
    formikName: string;
}

const MailSubjectInput : React.FC<MailSubjectInputProps> = props => {
    const [boxColor, setBoxColor] = useState("rgba(0, 0, 0, 0.23)");
    
    const [field, meta, helper] = useField({
        name: props.formikName
    });

    useEffect(() => {
        if(meta.error && meta.touched){
            setBoxColor(MpsTheme.palette.error.main);
        } else {
            setBoxColor("rgba(0, 0, 0, 0.23)");
        }
    }, [meta.error, meta.touched]);
    
    return (
      <Box sx={{mb: 2}}>
        <Typography variant={"h6"}>SUBJECT:</Typography>
          <Box
              sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  border: `1px solid ${boxColor}`,
                  borderRadius: "4px",
                  padding: "10px 5px",
                  alignItems: "center"
              }}
          >
              <Box sx={{flexGrow: 1}}>
                  <TextField
                      variant={"standard"}
                      autoComplete="off"
                      fullWidth
                      {...field}
                  />
              </Box>
          </Box>
      </Box>  
    );
}

export default observer(MailSubjectInput);