import {observer} from "mobx-react";
import {Box, Button, Chip, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useRootStore} from "../../../../core/stores/root-store";
import MpsSpinner from "../../../shared/mps-spinner";
import {EmailAddressItemResult, SendEmailRequest} from "@mpsinvoices/modelsclient/src";
import EmailApi from "../../../../core/api/email-api";
import * as Yup from "yup";
import MpsForm from "../../../shared/mps-form";
import {FormikHelpers, FormikValues} from "formik";
import MpsFormActions from "../../../shared/mps-form/components/mps-form-actions";
import MailToInput from "./mail-to-input";
import MailSubjectInput from "./mail-subject-input";
import MpsHtmlInput from "../../../shared/mps-form/components/mps-html-input";
import If from "../../../shared/if";
import {Email, PictureAsPdf} from "@mui/icons-material";
import {BusyStates} from "../../../../core/utilities/busy-states";
import {toast} from "react-toastify";

interface SendEmailDialogFormProps {
    onCancel: () => void;
    documentId?: string | null;
    onClose: () => void;
}

const initialEmailModel: SendEmailRequest = {
    recipientIds: [],
    subjectLine: "",
    emailBody: "",
    documentId: ""
}

const SendEmailDialogForm: React.FC<SendEmailDialogFormProps> = props => {

    const {busyStore} = useRootStore();
    const [initialLoading, setInitialLoading] = useState(false);
    const [data, setData] = useState<SendEmailRequest>(initialEmailModel);
    const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<EmailAddressItemResult[]>([]);

    useEffect(() => {
        if(props.documentId){
            setInitialLoading(true);
            EmailApi.getEmailAddressFromDocument({documentId: props.documentId})
                .then(result => {
                    let recipients: string[] = [];
                    if(result.model.emailAddressId){
                        recipients = [result.model.emailAddressId];
                        setSelectedEmailAddresses([result.model]);
                    }
                    setData({
                        ...initialEmailModel,
                        recipientIds: recipients,
                        documentId: props.documentId!
                    });
                })
                .catch(() => {
                    setData({
                        ...initialEmailModel,
                        documentId: props.documentId!
                    });
                })
                .finally(() => {
                    setInitialLoading(false);
                })
        } else {
            setData(initialEmailModel);
        }
    }, [props.documentId]);

    if(initialLoading) return <MpsSpinner noMessage />;
    
    const validationSchema = Yup.object().shape({
        recipientIds: Yup.array().required("Recipient is required").min(1, "Recipient is required"),
        subjectLine: Yup.string(),
        emailBody: Yup.string()
    });
    
    const handleSubmit = (values: (FormikValues & SendEmailRequest), actions: FormikHelpers<FormikValues & SendEmailRequest>) => {
        busyStore.setBusyState(BusyStates.Email);
        EmailApi.sendEmail(values)
            .then(() => {
                toast.success("Email sent successfully");
                props.onClose();
            })
            .catch(() => {
                toast.error("Failed to sent email")
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.Email);
            });
    }

    return (
        <>
            <DialogTitle>Send Email</DialogTitle>
            <MpsForm<SendEmailRequest>
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formProps => (
                    <>
                        <DialogContent>
                            <MailToInput
                                formikName={"recipientIds"}
                                selectedAddresses={selectedEmailAddresses}
                                onChange={items => setSelectedEmailAddresses(items)}
                                formProps={formProps}
                            />
                            <MailSubjectInput
                                formikName={"subjectLine"}
                            />
                            <If condition={formProps.values.documentId !== undefined && formProps.values.documentId !== null && formProps.values.documentId != ""}>
                                <Box sx={{mb: 2, display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
                                    <Typography variant={"h6"}>ATTACHEMENT:</Typography>
                                    <Chip
                                        icon={<PictureAsPdf />}
                                        label={`${formProps.values.documentId}.pdf`}    
                                    />
                                </Box>
                            </If>
                            <MpsHtmlInput
                                label={""}
                                formikName={"emailBody"}
                                minimalControls={true}
                                disabledMergeFields={true}
                                height={300}
                            />
                        </DialogContent>
                        <DialogActions>
                            <MpsFormActions
                                formProps={formProps}
                                onCancelAction={() => {
                                    props.onCancel();
                                }}
                                saveText={"Send"}
                            />
                        </DialogActions>
                    </>
                )}
            </MpsForm>
        </>
    )
}

export default observer(SendEmailDialogForm);